// ESignature.jsx
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { storage } from '../firebase';
import { ref, uploadString } from 'firebase/storage';
import { Button } from '@mui/material';

const ESignature = ({ jobId }) => {
  const sigCanvas = useRef({});

  const saveSignature = async () => {
    const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    const signatureRef = ref(storage, `jobs/${jobId}/signature.png`);
    await uploadString(signatureRef, dataURL, 'data_url');
    sigCanvas.current.clear();
    alert('Signature saved!');
  };

  return (
    <div>
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
      />
      <Button variant="contained" onClick={saveSignature} style={{ marginTop: '8px' }}>
        Save Signature
      </Button>
    </div>
  );
};

export default ESignature;
