// src/components/form/AddJobForm.jsx

import React, { useState, useEffect } from 'react';
import useCurrentUserWithShop from '../../hooks/useCurrentUserWithShop';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  MenuItem,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  doc,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { db } from '../../firebase';
import AddCustomerForm from './AddCustomerForm';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { startAt, endAt } from 'firebase/firestore';
import { generateSearchField } from '../../utils/generateSearchField'; // Import the utility function


const AddJobForm = ({ open, onClose }) => {
  const [customer, setCustomer] = useState(null);
  const [mechanic, setMechanic] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState('');
  const [customerNotes, setCustomerNotes] = useState('');
  const [serviceRequired, setServiceRequired] = useState([]); // Array of service objects
  const [appointmentDate, setAppointmentDate] = useState('');
  const [priorityLevel, setPriorityLevel] = useState('Normal');
  const [customersOptions, setCustomersOptions] = useState([]);
  const [mechanicsOptions, setMechanicsOptions] = useState([]);
  const [vehiclesOptions, setVehiclesOptions] = useState([]);
  const [presetServices, setPresetServices] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingMechanics, setLoadingMechanics] = useState(false);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAddCustomerModal, setOpenAddCustomerModal] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [vehiclesError, setVehiclesError] = useState('');

  const { currentUser, loading: userLoading } = useCurrentUserWithShop();
  const shopId = currentUser?.shopId;

  // Fetch preset services from Firestore
  useEffect(() => {
    const fetchPresetServices = async () => {
      if (!shopId) {
        console.error('shopId is undefined');
        return;
      }
      setLoadingServices(true);
      try {
        const servicesRef = collection(db, 'services');
        const q = query(
          servicesRef,
          where('shopId', '==', shopId),
          orderBy('name'),
          limit(100) // Adjust as needed
        );
        const querySnapshot = await getDocs(q);
        const services = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPresetServices(services);
      } catch (error) {
        console.error('Error fetching services:', error);
        setError('Failed to fetch preset services.');
      }
      setLoadingServices(false);
    };

    if (open && shopId) {
      fetchPresetServices();
    }
  }, [open, shopId]);

  useEffect(() => {
    if (!userLoading && currentUser && shopId) {
      fetchCustomers();
      fetchMechanics();
    }
    // eslint-disable-next-line
  }, [userLoading, currentUser, shopId]);

  // Fetch customers based on input
  const fetchCustomers = async (searchText = '') => {
    if (!shopId) {
      console.error('shopId is undefined');
      return;
    }
    setLoadingCustomers(true);
    try {
      const customersRef = collection(db, 'customers');
      let q;
      if (searchText) {
        q = query(
          customersRef,
          where('shopId', '==', shopId),
          orderBy('name'), // Assuming 'name' is a searchable field
          startAt(searchText.toLowerCase()),
          endAt(searchText.toLowerCase() + '\uf8ff'),
          limit(20)
        );
      } else {
        q = query(
          customersRef,
          where('shopId', '==', shopId),
          orderBy('name'),
          limit(20)
        );
      }
      const querySnapshot = await getDocs(q);
      const customers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCustomersOptions(customers);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Failed to fetch customers.');
    }
    setLoadingCustomers(false);
  };

  // Fetch mechanics
  const fetchMechanics = async (searchText = '') => {
    if (!shopId) {
      console.error('shopId is undefined');
      return;
    }
    setLoadingMechanics(true);
    try {
      const usersRef = collection(db, 'users');
      let q;
      if (searchText) {
        q = query(
          usersRef,
          where('shopId', '==', shopId),
          where('role', '==', 'Mechanic'),
          orderBy('name'),
          startAt(searchText.toLowerCase()),
          endAt(searchText.toLowerCase() + '\uf8ff'),
          limit(20)
        );
      } else {
        q = query(
          usersRef,
          where('shopId', '==', shopId),
          where('role', '==', 'Mechanic'),
          orderBy('name'),
          limit(20)
        );
      }
      const querySnapshot = await getDocs(q);
      const mechanics = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMechanicsOptions(mechanics);
    } catch (error) {
      console.error('Error fetching mechanics:', error);
      setError('Failed to fetch mechanics.');
    }
    setLoadingMechanics(false);
  };

  // Fetch vehicles for selected customer
  const fetchVehicles = async (customerId) => {
    if (!shopId) {
      console.error('shopId is undefined');
      return;
    }
    setLoadingVehicles(true);
    try {
      const vehiclesRef = collection(db, 'customers', customerId, 'vehicles');
      const q = query(
        vehiclesRef,
        orderBy('make'),
        limit(20)
      );
      const querySnapshot = await getDocs(q);
      const vehicles = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVehiclesOptions(vehicles);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setVehiclesError('Failed to fetch vehicles.');
    }
    setLoadingVehicles(false);
  };

  // Handle customer selection
  useEffect(() => {
    if (customer) {
      fetchVehicles(customer.id);
    } else {
      setVehiclesOptions([]);
      setVehicle(null);
    }
    // eslint-disable-next-line
  }, [customer]);

  // Handle adding a new service row
  const handleAddServiceRow = () => {
    setServiceRequired([
      ...serviceRequired,
      {
        serviceId: null, // Custom service
        serviceName: '',
        price: 0,
        customerPrice: 0,
      },
    ]);
  };

  // Handle removing a service row
  const handleRemoveServiceRow = (index) => {
    const updatedServices = [...serviceRequired];
    updatedServices.splice(index, 1);
    setServiceRequired(updatedServices);
  };

  // Handle changing service details
  const handleServiceChange = (index, field, value) => {
    const updatedServices = [...serviceRequired];
    updatedServices[index][field] = value;

    // If serviceId is set, auto-fill price from presetServices
    if (field === 'serviceId' && value) {
      const selectedService = presetServices.find((service) => service.id === value);
      if (selectedService) {
        updatedServices[index]['serviceName'] = selectedService.name;
        updatedServices[index]['price '] = selectedService.price  || 0;
      }
    }

    setServiceRequired(updatedServices);
  };

  // Remove the function that adds custom services to the global 'services' collection
  // const addCustomServicesToFirestore = async () => { ... } // Remove this

  const handleSubmit = async () => {
    // Input validation
    if (!customer) {
      setError('Please select a customer.');
      return;
    }
    if (!vehicle) {
      setError('Please select a vehicle.');
      return;
    }
    if (!estimatedTime || isNaN(estimatedTime) || parseFloat(estimatedTime) <= 0) {
      setError('Please enter a valid estimated time.');
      return;
    }
    if (serviceRequired.length === 0) {
      setError('Please add at least one service.');
      return;
    }
    // Validate each service
    for (let i = 0; i < serviceRequired.length; i++) {
      const service = serviceRequired[i];
      if (!service.serviceName || service.serviceName.trim() === '') {
        setError(`Service name at row ${i + 1} is required.`);
        return;
      }
      if (service.customerPrice < 0) {
        setError(`Customer price at row ${i + 1} cannot be negative.`);
        return;
      }
    }

    const computeSearchField = (jobData, customerData, mechanicData, vehicleData) => {
      return generateSearchField(jobData, customerData, mechanicData, vehicleData);
    };
    
    setLoading(true);
    setError(null);
    try {
      // Prepare services data
      const servicesData = serviceRequired.map((service) => ({
        serviceId: service.serviceId || null,
        serviceName: service.serviceName,
        price: service.price || 0,
      }));

      // Create a job data object for searchField computation
      const jobDataForSearch = {
        serviceRequired: servicesData,
        // Include other necessary fields if needed
      };

      // Compute searchField
      const searchField = computeSearchField(
        jobDataForSearch,
        customer,
        mechanic,
        vehicle
      );

      // Add job document with searchField
      const jobRef = await addDoc(collection(db, 'jobs'), {
        customerId: customer.id,
        mechanicId: mechanic ? mechanic.id : null,
        vehicleId: vehicle.id,
        shopId,
        status: 'Pending',
        estimatedTime: parseFloat(estimatedTime),
        actualTime: 0,
        customerNotes,
        serviceRequired: servicesData,
        appointmentDate: appointmentDate ? new Date(appointmentDate) : null,
        priorityLevel,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        searchField, // Include the searchField here
      });

      // If a mechanic is assigned, add this job to their assignedJobs array
      if (mechanic) {
        const mechanicRef = doc(db, 'users', mechanic.id);
        await updateDoc(mechanicRef, {
          assignedJobs: arrayUnion(jobRef.id),
        });
      }

      setLoading(false);
      setSuccessMessage('Job added successfully!');
      onClose();
    } catch (error) {
      console.error('Error adding job:', error);
      setError('Failed to add job. Please try again.');
      setLoading(false);
    }
  };

  // Render Services Required as a table for detailed input
  const renderServicesTable = () => (
    <TableContainer component={Paper}>
      <Table aria-label="services required table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '75%' }}>Service</TableCell>
            <TableCell sx={{ width: '20%' }}>Price ($)</TableCell>
            <TableCell align="center" sx={{ width: '5%' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceRequired.map((service, index) => (
            <TableRow key={index}>
              <TableCell>
                <Autocomplete
                  options={presetServices}
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || '')}
                  value={service.serviceName || ''}
                  freeSolo
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      // Custom input as service name
                      handleServiceChange(index, 'serviceId', null); // Reset serviceId
                      handleServiceChange(index, 'serviceName', newValue);
                    } else if (newValue && newValue.id) {
                      // Preset service selected
                      handleServiceChange(index, 'serviceId', newValue.id);
                      handleServiceChange(index, 'serviceName', newValue.name);
                      handleServiceChange(index, 'price', newValue.price || 0);
                    } else {
                      // Reset to empty
                      handleServiceChange(index, 'serviceId', null);
                      handleServiceChange(index, 'serviceName', '');
                      handleServiceChange(index, 'price', 0);
                    }
                  }}
                  onInputChange={(event, inputValue) => {
                    // Update the service name when typing in freeSolo mode
                    handleServiceChange(index, 'serviceName', inputValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Service" variant="outlined" required fullWidth />
                  )}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  type="number"
                  value={service.price}
                  onChange={(e) =>
                    handleServiceChange(index, 'price', parseFloat(e.target.value) || 0)
                  }
                  inputProps={{ step: 0.01, min: 0 }}
                  fullWidth
                  size="small"
                />
              </TableCell>
              <TableCell align="center">
                <IconButton aria-label="delete service" color="error" onClick={() => handleRemoveServiceRow(index)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {/* Add New Service Row */}
          <TableRow>
            <TableCell colSpan={3} align="center">
              <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddServiceRow}>
                Add Service
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
  

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: 'white',
          padding: 4,
          margin: 'auto',
          marginTop: '5%',
          width: { xs: '95%', sm: '90%', md: '70%' },
          borderRadius: 2,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Add New Job
        </Typography>

        {/* Display Success Message */}
        {successMessage && (
          <Alert
            severity="success"
            onClose={() => setSuccessMessage('')}
            sx={{ marginBottom: 2 }}
          >
            {successMessage}
          </Alert>
        )}

        {/* Display Error Message */}
        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{ marginBottom: 2 }}
          >
            {error}
          </Alert>
        )}

        <Grid container spacing={2}>
          {/* Customer Selection */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Autocomplete
                options={customersOptions}
                getOptionLabel={(option) =>
                  `${option.name} (${option.email}, ${option.contactNumber})`
                }
                loading={loadingCustomers}
                onInputChange={(event, value) => fetchCustomers(value)}
                onChange={(event, value) => setCustomer(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Customer"
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingCustomers ? <CircularProgress size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                sx={{ flexGrow: 1 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenAddCustomerModal(true)}
                sx={{ marginLeft: 2 }}
              >
                Add Customer
              </Button>
            </Box>
          </Grid>

          {/* Vehicle Selection */}
          {customer && (
            <Grid item xs={12}>
              <Autocomplete
                options={vehiclesOptions}
                getOptionLabel={(option) => `${option.make} ${option.model} (${option.year})`}
                loading={loadingVehicles}
                onChange={(event, value) => setVehicle(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Vehicle"
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingVehicles ? <CircularProgress size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {/* Mechanic Selection */}
          <Grid item xs={12}>
            <Autocomplete
              options={mechanicsOptions}
              getOptionLabel={(option) => option.name || ''}
              loading={loadingMechanics}
              onInputChange={(event, value) => fetchMechanics(value)}
              onChange={(event, value) => setMechanic(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Mechanic (Optional)"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingMechanics ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          {/* Services Required */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              Services Required
            </Typography>
            {renderServicesTable()}
          </Grid>

          {/* Estimated Time */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Estimated Time (hrs)"
              variant="outlined"
              fullWidth
              required
              type="number"
              value={estimatedTime}
              onChange={(e) => setEstimatedTime(e.target.value)}
              inputProps={{ step: 0.25, min: 0 }}
              helperText="Enter time in 0.25-hour increments (e.g., 1.25 for 1 hour 15 minutes)"
            />
          </Grid>

          {/* Appointment Date */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Appointment Date"
              variant="outlined"
              fullWidth
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              value={appointmentDate}
              onChange={(e) => setAppointmentDate(e.target.value)}
            />
          </Grid>

          {/* Priority Level */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Priority Level"
              variant="outlined"
              fullWidth
              select
              value={priorityLevel}
              onChange={(e) => setPriorityLevel(e.target.value)}
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Normal">Normal</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </TextField>
          </Grid>

          {/* Customer Notes */}
          <Grid item xs={12}>
            <TextField
              label="Customer Notes"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={customerNotes}
              onChange={(e) => setCustomerNotes(e.target.value)}
            />
          </Grid>

          {/* Action Buttons */}
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Button onClick={onClose} sx={{ marginRight: 2 }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading || loadingServices}
            >
              {loading || loadingServices ? <CircularProgress size={24} /> : 'Add Job'}
            </Button>
          </Grid>
        </Grid>

        {/* AddCustomerForm Modal */}
        {openAddCustomerModal && (
          <AddCustomerForm
            open={openAddCustomerModal}
            onClose={() => {
              setOpenAddCustomerModal(false);
              fetchCustomers(); // Refresh customer list after adding a new customer
            }}
          />
        )}
      </Box>
    </Modal>
  );
};

export default AddJobForm;
