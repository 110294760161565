// src/theme.js

import { createTheme } from '@mui/material/styles';
import { pink, green } from '@mui/material/colors'; // Import necessary colors

const theme = createTheme({
  palette: {
    primary: {
      main: '#f96c61',
      contrastText: '#ffffff', // Text color on primary backgrounds
    },
    secondary: {
      main: green[500], // Keep your custom green or adjust as needed
      contrastText: '#ffffff',
    },
    background: {
      default: '#f5f5f5', // Default background color
      paper: '#ffffff', // Background color for Paper components
    },
    text: {
      primary: '#333333', // Primary text color
      secondary: '#757575', // Secondary text color
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h4: {
      fontWeight: 600,
    },
    // Add more typography customizations as needed
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ffffff', // Sidebar background color
          color: '#333333', // Sidebar text color
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: pink[200], // Icons color in the sidebar (primary color)
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: '#757575', // Text color in the sidebar
          fontWeight: 600,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true, // Disable ripple effect globally
      },
      styleOverrides: {
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    // Add more component customizations as needed
  },
});

export default theme;
