// src/pages/JobManagementPage.jsx

import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  InputAdornment,
  useMediaQuery,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  TableFooter, // Import TableFooter
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  arrayRemove,
} from 'firebase/firestore';
import { auth, db } from '../firebase';
import AddJobForm from '../components/form/AddJobForm';
import JobDetailsModal from '../components/modal/JobDetailsModal';
import useCurrentUserWithShop from '../hooks/useCurrentUserWithShop';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash.debounce';
import { useAuthState } from 'react-firebase-hooks/auth';
import DeleteIcon from '@mui/icons-material/Delete';

const JobManagement = () => {
  const [jobs, setJobs] = useState([]);
  const [combinedJobs, setCombinedJobs] = useState([]); // Jobs with related data
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [priorityFilter, setPriorityFilter] = useState('');
  const [mechanicFilter, setMechanicFilter] = useState(null); // Object
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc'); // 'asc' or 'desc'
  const [openAddJob, setOpenAddJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [mechanics, setMechanics] = useState([]);
  const [loadingMechanics, setLoadingMechanics] = useState(false);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser, loading: userLoading } = useCurrentUserWithShop();

  // State for user privileges
  const [hasViewCustomers, setHasViewCustomers] = useState(false);
  const [privilegesLoading, setPrivilegesLoading] = useState(true);
  const [privilegesError, setPrivilegesError] = useState(null);

  // State for banner messages
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerSeverity, setBannerSeverity] = useState('success'); // 'success' | 'error'
  const [bannerVisible, setBannerVisible] = useState(false);

  // Get current authenticated user
  const [authUser, authLoading, authError] = useAuthState(auth);

  // State for Delete Confirmation Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);

  // Debounced fetchMechanics to optimize performance
  const debouncedFetchMechanics = useMemo(
    () =>
      debounce((searchText) => {
        fetchMechanics(searchText);
      }, 300), // 300ms debounce delay
    []
  );

  // Debounce the search term input
  const debouncedSetSearchTerm = useMemo(
    () =>
      debounce((term) => {
        setDebouncedSearchTerm(term);
      }, 500), // 500ms debounce delay
    []
  );

  // Clean up the debounce on unmount
  useEffect(() => {
    return () => {
      debouncedFetchMechanics.cancel();
      debouncedSetSearchTerm.cancel();
    };
  }, [debouncedFetchMechanics, debouncedSetSearchTerm]);

  // Fetch user privileges
  useEffect(() => {
    const fetchPrivileges = async () => {
      if (!authUser) {
        setHasViewCustomers(false);
        setPrivilegesLoading(false);
        return;
      }

      try {
        const userDocRef = doc(db, 'users', authUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userPrivileges = userData.privileges || {};
          setHasViewCustomers(Boolean(userPrivileges.viewCustomers));
        } else {
          console.warn('User document does not exist.');
          setHasViewCustomers(false);
        }
      } catch (error) {
        console.error('Error fetching user privileges:', error);
        setPrivilegesError('Failed to fetch user privileges.');
      }
      setPrivilegesLoading(false);
    };

    fetchPrivileges();
  }, [authUser]);

  useEffect(() => {
    if (!userLoading && currentUser) {
      fetchMechanics();
      fetchJobs();
    }
    // eslint-disable-next-line
  }, [currentUser, userLoading]);

  useEffect(() => {
    if (!userLoading && currentUser) {
      fetchJobs();
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm, statusFilter, priorityFilter, mechanicFilter, sortField, sortOrder, hasViewCustomers]);

  // Debounce the search term
  useEffect(() => {
    debouncedSetSearchTerm(searchTerm);
  }, [searchTerm, debouncedSetSearchTerm]);

  // Fetch mechanics from the 'users' collection with role 'Mechanic'
  const fetchMechanics = async (searchText = '') => {
    if (!currentUser || !currentUser.shopId) return;

    setLoadingMechanics(true);

    try {
      const usersRef = collection(db, 'users');
      let q;

      if (searchText) {
        q = query(
          usersRef,
          where('shopId', '==', currentUser.shopId),
          where('role', '==', 'Mechanic'),
          where('searchField', '>=', searchText.toLowerCase()),
          where('searchField', '<=', searchText.toLowerCase() + '\uf8ff'),
          orderBy('searchField', 'asc'),
          limit(50) // Adjust the limit as needed
        );
      } else {
        q = query(
          usersRef,
          where('shopId', '==', currentUser.shopId),
          where('role', '==', 'Mechanic'),
          orderBy('name'),
          limit(50) // Adjust the limit as needed
        );
      }
      const querySnapshot = await getDocs(q);
      const mechanicList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMechanics(mechanicList);
    } catch (error) {
      console.error('Error fetching mechanics:', error);
      setError('An error occurred while fetching mechanics.');
      showBanner('Error fetching mechanics. Please try again.', 'error');
    }

    setLoadingMechanics(false);
  };

  // Fetch jobs based on filters and sorting
  const fetchJobs = async () => {
    setLoading(true);
    setError(null);

    if (!currentUser || !currentUser.shopId) {
      setError('User not logged in or shop information is missing.');
      setLoading(false);
      return;
    }

    try {
      const jobsRef = collection(db, 'jobs');
      const constraints = [where('shopId', '==', currentUser.shopId)];

      // Apply Status Filter
      if (statusFilter) {
        constraints.push(where('status', '==', statusFilter));
      }

      // Apply Priority Filter
      if (priorityFilter) {
        constraints.push(where('priorityLevel', '==', priorityFilter));
      }

      // Apply Mechanic Filter
      if (mechanicFilter && mechanicFilter.id) {
        constraints.push(where('mechanicId', '==', mechanicFilter.id));
      }

      // Apply Search Filter
      if (debouncedSearchTerm) {
        const lowerCaseSearchTerm = debouncedSearchTerm.toLowerCase();
        constraints.push(
          where('searchField', '>=', lowerCaseSearchTerm),
          where('searchField', '<=', lowerCaseSearchTerm + '\uf8ff')
        );
      }

      // Apply Sorting
      if (debouncedSearchTerm) {
        // When searching, order by 'searchField' first
        constraints.push(orderBy('searchField', 'asc'));
      }

      if (sortField && sortField !== 'searchField') {
        constraints.push(orderBy(sortField, sortOrder));
      } else if (!debouncedSearchTerm) {
        // If not searching, default sorting
        constraints.push(orderBy('createdAt', 'desc'));
      }

      constraints.push(limit(50)); // Adjust as needed

      const qJobs = query(jobsRef, ...constraints);
      const jobSnapshot = await getDocs(qJobs);
      const jobList = jobSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setJobs(jobList);

      // After fetching jobs, fetch related data
      await fetchRelatedData(jobList);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setError('An error occurred while fetching jobs.');
      showBanner('Error fetching jobs. Please try again.', 'error');
    }
    setLoading(false);
  };

  // Function to fetch related data based on jobList
  const fetchRelatedData = async (jobList) => {
    const combined = [];

    // Determine if we need to fetch customer data
    const needCustomerData = hasViewCustomers;

    // Extract unique IDs
    const customerIds = needCustomerData ? [...new Set(jobList.map((job) => job.customerId))] : [];
    const mechanicIds = [...new Set(jobList.map((job) => job.mechanicId))];
    const vehicleIds = [...new Set(jobList.map((job) => job.vehicleId))];

    // Fetch customers if needed
    const customersMap = needCustomerData ? await fetchDocuments('customers', customerIds) : {};

    // Fetch mechanics
    const mechanicsMap = await fetchDocuments('users', mechanicIds);

    // Fetch vehicles since they are in subcollections
    const vehiclesMap = await fetchVehicles(jobList);

    // Combine job data with related data
    const combinedList = jobList.map((job) => ({
      ...job,
      customerName: needCustomerData ? (customersMap[job.customerId]?.name || 'N/A') : undefined,
      customerEmail: needCustomerData ? (customersMap[job.customerId]?.email || 'N/A') : undefined,
      customerContactNumber: needCustomerData ? (customersMap[job.customerId]?.contactNumber || 'N/A') : undefined,
      mechanicName: mechanicsMap[job.mechanicId]?.name || 'N/A',
      mechanicEmail: mechanicsMap[job.mechanicId]?.email || 'N/A',
      vehicleMake: vehiclesMap[job.vehicleId]?.make || 'N/A',
      vehicleModel: vehiclesMap[job.vehicleId]?.model || 'N/A',
      vehicleYear: vehiclesMap[job.vehicleId]?.year || 'N/A',
    }));

    setCombinedJobs(combinedList);
  };

  // Generic function to fetch documents by IDs from a collection
  const fetchDocuments = async (collectionName, ids) => {
    const docsMap = {};
    if (ids.length === 0) return docsMap;

    const batchSize = 10; // Firestore allows up to 10 in 'in' queries
    for (let i = 0; i < ids.length; i += batchSize) {
      const batchIds = ids.slice(i, i + batchSize);
      const q = query(collection(db, collectionName), where('__name__', 'in', batchIds));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        docsMap[doc.id] = doc.data();
      });
    }
    return docsMap;
  };

  // Function to fetch vehicles since they are in subcollections
  const fetchVehicles = async (jobList) => {
    const vehiclesMap = {};
    const vehiclePromises = jobList.map(async (job) => {
      if (job.vehicleId && job.customerId) {
        try {
          const vehicleRef = doc(db, 'customers', job.customerId, 'vehicles', job.vehicleId);
          const vehicleDoc = await getDoc(vehicleRef);
          if (vehicleDoc.exists()) {
            vehiclesMap[job.vehicleId] = vehicleDoc.data();
          } else {
            vehiclesMap[job.vehicleId] = null;
          }
        } catch (error) {
          console.error(`Error fetching vehicle ${job.vehicleId}:`, error);
          vehiclesMap[job.vehicleId] = null;
        }
      }
    });

    await Promise.all(vehiclePromises);
    return vehiclesMap;
  };

  const openAddJobForm = () => {
    setOpenAddJob(true);
  };

  const closeAddJobForm = () => {
    setOpenAddJob(false);
    fetchJobs();
  };

  const openJobDetails = (job) => {
    setSelectedJob(job);
  };

  const closeJobDetails = () => {
    setSelectedJob(null);
    fetchJobs();
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      // Toggle sort order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  // Function to show banner messages
  const showBanner = (message, severity = 'success') => {
    setBannerMessage(message);
    setBannerSeverity(severity);
    setBannerVisible(true);
  };

  // Handler to open Delete Confirmation Dialog
  const handleDeleteJob = (job) => {
    setJobToDelete(job);
    setOpenDeleteDialog(true);
  };

  // Handler to confirm deletion
  const confirmDeleteJob = async () => {
    if (!jobToDelete) return;

    setOpenDeleteDialog(false);
    setLoading(true);
    setError(null);
    try {
      // Delete the job document
      const jobRef = doc(db, 'jobs', jobToDelete.id);
      await deleteDoc(jobRef);

      // If a mechanic is assigned, remove the job from their assignedJobs
      if (jobToDelete.mechanicId) {
        const mechanicRef = doc(db, 'users', jobToDelete.mechanicId);
        await updateDoc(mechanicRef, {
          assignedJobs: arrayRemove(jobToDelete.id),
        });
      }

      setLoading(false);
      showBanner('Job deleted successfully!', 'success');
      fetchJobs(); // Refresh the jobs list
    } catch (error) {
      console.error('Error deleting job:', error);
      setLoading(false);
      showBanner('Error deleting job. Please try again.', 'error');
    }
  };

  // Handler to cancel deletion
  const cancelDeleteJob = () => {
    setOpenDeleteDialog(false);
    setJobToDelete(null);
  };

  // Calculate Grand Total (sum of all jobs' total costs)
  const grandTotal = combinedJobs.reduce((acc, job) => {
    const jobTotal = Array.isArray(job.serviceRequired)
      ? job.serviceRequired.reduce((sum, service) => sum + (parseFloat(service.price) || 0), 0)
      : parseFloat(job.serviceRequired.price) || 0;
    return acc + jobTotal;
  }, 0);

  if (userLoading || privilegesLoading || authLoading) {
    return (
      <Box sx={{ padding: '16px', textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!currentUser) {
    return (
      <Box sx={{ padding: '16px' }}>
        <Typography variant="h6">Please log in to view jobs.</Typography>
      </Box>
    );
  }

  if (privilegesError) {
    return (
      <Box sx={{ padding: '16px' }}>
        <Alert severity="error">{privilegesError}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      {/* Banner Message */}
      {bannerVisible && (
        <Snackbar
          open={Boolean(bannerMessage)}
          autoHideDuration={6000}
          onClose={() => setBannerVisible(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setBannerVisible(false)}
            severity={bannerSeverity}
            sx={{ width: '100%' }}
          >
            {bannerMessage}
          </Alert>
        </Snackbar>
      )}

      <Typography variant="h4" gutterBottom>
        Job Management
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        {/* Search Field */}
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Search Jobs"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
              style: { height: '56px' },
            }}
          />
        </Grid>

        {/* Status Filter */}
        <Grid item xs={6} sm={3} md={2}>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ height: '56px' }}
            renderValue={(selected) => (selected ? selected : 'All Statuses')}
          >
            <MenuItem value="">All Statuses</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        </Grid>

        {/* Priority Filter */}
        <Grid item xs={6} sm={3} md={2}>
          <Select
            value={priorityFilter}
            onChange={(e) => setPriorityFilter(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ height: '56px' }}
            renderValue={(selected) => (selected ? selected : 'All Priorities')}
          >
            <MenuItem value="">All Priorities</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Normal">Normal</MenuItem>
            <MenuItem value="High">High</MenuItem>
          </Select>
        </Grid>

        {/* Mechanic Filter with Enhanced Autocomplete */}
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={mechanics}
            getOptionLabel={(option) => option.name || option.displayName || ''}
            value={mechanicFilter}
            onChange={(event, newValue) => {
              setMechanicFilter(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              debouncedFetchMechanics(newInputValue);
            }}
            loading={loadingMechanics}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Mechanic"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  style: { height: '56px' },
                  endAdornment: (
                    <>
                      {loadingMechanics ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: '300px', // Adjust the max height as needed
              },
            }}
          />
        </Grid>

        {/* Sorting Field */}
        <Grid item xs={12} sm={6} md={3}>
          <Select
            value={sortField}
            onChange={(e) => setSortField(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ height: '56px' }}
            renderValue={(selected) => (selected ? selected : 'Sort By')}
          >
            <MenuItem value="createdAt">Sort by Date Added</MenuItem>
            <MenuItem value="priorityLevel">Sort by Priority</MenuItem>
            <MenuItem value="appointmentDate">Sort by Appointment Date</MenuItem>
            <MenuItem value="serviceRequired">Sort by Service</MenuItem>
            <MenuItem value="customerName">Sort by Customer Name</MenuItem>
            <MenuItem value="totalCost">Sort by Total Cost</MenuItem> {/* New Sort Option */}
          </Select>
        </Grid>

        {/* Sorting Order */}
        <Grid item xs={6} sm={3} md={1}>
          <Button
            variant="outlined"
            onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
            fullWidth
            sx={{ height: '56px' }}
          >
            {sortOrder === 'asc' ? 'Asc' : 'Desc'}
          </Button>
        </Grid>

        {/* Add Job Button */}
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              justifyContent: 'space-between',
              alignItems: 'stretch',
              height: '56px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={openAddJobForm}
              sx={{
                flex: 1,
              }}
            >
              Add New Job
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Error Alert */}
      {error && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ textAlign: 'center', marginTop: 4 }}>
          <CircularProgress />
        </Box>
      ) : combinedJobs.length > 0 ? (
        isMobile ? (
          // Mobile View: Detailed List
          <List>
            {combinedJobs.map((job) => {
              // Calculate total cost
              const totalCost = Array.isArray(job.serviceRequired)
                ? job.serviceRequired.reduce((acc, service) => acc + (parseFloat(service.price) || 0), 0)
                : parseFloat(job.serviceRequired.price) || 0;

              return (
                <ListItem
                  key={job.id}
                  button
                  onClick={() => openJobDetails(job)}
                  divider
                >
                  <ListItemText
                    primary={`${Array.isArray(job.serviceRequired) ? job.serviceRequired.map(s => s.serviceName).join(', ') : job.serviceRequired.serviceName} - ${job.status}`}
                    secondary={
                      <>
                        {hasViewCustomers && (
                          <>
                            <Typography variant="body2" color="textPrimary">
                              Customer: {job.customerName || 'N/A'}
                            </Typography>
                            <Typography variant="body2" color="textPrimary">
                              Email: {job.customerEmail || 'N/A'}
                            </Typography>
                            <Typography variant="body2" color="textPrimary">
                              Contact: {job.customerContactNumber || 'N/A'}
                            </Typography>
                          </>
                        )}
                        <Typography variant="body2" color="textPrimary">
                          Appointment:{' '}
                          {job.appointmentDate
                            ? new Date(job.appointmentDate.seconds * 1000).toLocaleString()
                            : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="textPrimary">
                          Priority: {job.priorityLevel}
                        </Typography>
                        <Typography variant="body2" color="textPrimary">
                          Mechanic: {job.mechanicName || 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="textPrimary">
                          Vehicle: {job.vehicleMake} {job.vehicleModel} ({job.vehicleYear})
                        </Typography>
                        <Typography variant="body2" color="textPrimary">
                          Total Cost: {totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                        </Typography>
                      </>
                    }
                  />
                  {/* Delete Button for Mobile View */}
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the parent click
                      handleDeleteJob(job);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
        ) : (
          // Desktop View: Table with Conditional Customer Columns
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            <Table aria-label="jobs table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('serviceRequired')}
                      endIcon={
                        sortField === 'serviceRequired' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Service Required
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('status')}
                      endIcon={
                        sortField === 'status' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Status
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('priorityLevel')}
                      endIcon={
                        sortField === 'priorityLevel' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Priority
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('appointmentDate')}
                      endIcon={
                        sortField === 'appointmentDate' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Appointment Date
                    </Button>
                  </TableCell>
                  {hasViewCustomers && (
                    <TableCell>
                      <Button
                        onClick={() => handleSortChange('customerName')}
                        endIcon={
                          sortField === 'customerName' ? (
                            sortOrder === 'asc' ? (
                              <Typography>&uarr;</Typography>
                            ) : (
                              <Typography>&darr;</Typography>
                            )
                          ) : null
                        }
                        sx={{ textTransform: 'none', fontWeight: 600 }}
                      >
                        Customer
                      </Button>
                    </TableCell>
                  )}
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('vehicleMake')}
                      endIcon={
                        sortField === 'vehicleMake' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Vehicle
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('mechanicName')}
                      endIcon={
                        sortField === 'mechanicName' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Mechanic
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('totalCost')}
                      endIcon={
                        sortField === 'totalCost' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Total Cost
                    </Button>
                  </TableCell>
                  <TableCell align="center">Actions</TableCell> {/* Existing Actions Column */}
                </TableRow>
              </TableHead>
              <TableBody>
                {combinedJobs.map((job) => {
                  // Calculate total cost
                  const totalCost = Array.isArray(job.serviceRequired)
                    ? job.serviceRequired.reduce((acc, service) => acc + (parseFloat(service.price) || 0), 0)
                    : parseFloat(job.serviceRequired.price) || 0;

                  return (
                    <TableRow
                      key={job.id}
                      hover
                      onClick={() => openJobDetails(job)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        {Array.isArray(job.serviceRequired)
                          ? job.serviceRequired.map(s => s.serviceName).join(', ')
                          : job.serviceRequired.serviceName}
                      </TableCell>
                      <TableCell>{job.status}</TableCell>
                      <TableCell>{job.priorityLevel}</TableCell>
                      <TableCell>
                        {job.appointmentDate
                          ? new Date(job.appointmentDate.seconds * 1000).toLocaleString()
                          : 'N/A'}
                      </TableCell>
                      {hasViewCustomers && <TableCell>{job.customerName || 'N/A'}</TableCell>}
                      <TableCell>
                        {job.vehicleMake} {job.vehicleModel} ({job.vehicleYear})
                      </TableCell>
                      <TableCell>{job.mechanicName || 'N/A'}</TableCell>
                      {/* New Total Cost Cell */}
                      <TableCell>
                        {totalCost.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering the parent click
                            handleDeleteJob(job);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {/* Optional: Grand Total Footer */}
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={hasViewCustomers ? 7 : 6}>
                    <Typography variant="subtitle1" align="right" sx={{ fontWeight: 'bold' }}>
                      Grand Total:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {grandTotal.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell /> {/* Empty cell for Actions column */}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )
      ) : (
        <Typography>No jobs found matching your search criteria.</Typography>
      )}

      {/* Add Job Form Modal */}
      <AddJobForm open={openAddJob} onClose={closeAddJobForm} />

      {/* Job Details Modal */}
      {selectedJob && (
        <JobDetailsModal job={selectedJob} open={Boolean(selectedJob)} onClose={closeJobDetails} />
      )}

      {/* Confirmation Dialog for Deletion */}
      <Dialog
        open={openDeleteDialog}
        onClose={cancelDeleteJob}
        aria-labelledby="delete-job-dialog-title"
        aria-describedby="delete-job-dialog-description"
      >
        <DialogTitle id="delete-job-dialog-title">Delete Job</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-job-dialog-description">
            Are you sure you want to delete this job? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteJob}>Cancel</Button>
          <Button onClick={confirmDeleteJob} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default JobManagement;
