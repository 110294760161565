// src/pages/UserManagementPage.jsx

import React, { useEffect, useState, useMemo } from 'react';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import { Search, Delete as DeleteIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddUserForm from '../components/form/AddUserForm';
import useCurrentUserWithShop from '../hooks/useCurrentUserWithShop';
import UserDetailsModal from '../components/modal/UserDetailsModal';
import { getUsersFromFirestore, updateUserInFirestore, deleteUserFromFirestore } from '../firebase'; // Ensure correct path
import useUserPrivileges from '../hooks/useUserPrivileges';
import debounce from 'lodash.debounce';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
  const [openAddUser, setOpenAddUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser, loading: userLoading } = useCurrentUserWithShop();

  const { privileges, privilegesLoading, privilegesError } = useUserPrivileges();

  // Debounce the search term input
  const debouncedSetSearchTerm = useMemo(
    () =>
      debounce((term) => {
        setDebouncedSearchTerm(term);
      }, 500), // 500ms debounce delay
    []
  );

  // Clean up the debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSetSearchTerm.cancel();
    };
  }, [debouncedSetSearchTerm]);

  useEffect(() => {
    if (!userLoading && currentUser) {
      fetchUsers();
    }
    // eslint-disable-next-line
  }, [currentUser, userLoading, debouncedSearchTerm, roleFilter, statusFilter, sortField, sortOrder]);

  // Debounce the search term
  useEffect(() => {
    debouncedSetSearchTerm(searchTerm);
  }, [searchTerm, debouncedSetSearchTerm]);

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);

    if (!currentUser || !currentUser.shopId) {
      setError('User not logged in or shop information is missing.');
      setLoading(false);
      return;
    }

    try {
      // Use the imported getUsersFromFirestore function
      const userList = await getUsersFromFirestore(
        currentUser.shopId,
        debouncedSearchTerm,
        roleFilter,
        statusFilter,
        sortField,
        sortOrder
      );
      setUsers(userList);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('An error occurred while fetching users.');
    }
    setLoading(false);
  };

  const openAddUserForm = () => {
    setOpenAddUser(true);
  };

  const closeAddUserForm = () => {
    setOpenAddUser(false);
    fetchUsers();
  };

  const openUserDetails = (user) => {
    setSelectedUser(user);
  };

  const closeUserDetails = () => {
    setSelectedUser(null);
    // Optionally, refetch users if needed
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      // Toggle sort order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  /**
   * Handles saving updated user data.
   * @param {Object} updatedUser - The user object with updated data.
   */
  const handleSaveUser = async (updatedUser) => {
    if (!updatedUser.id) {
      console.error('User ID is missing');
      setErrorMessage('User ID is missing.');
      return;
    }

    try {
      // Update user in Firestore
      await updateUserInFirestore(updatedUser.id, updatedUser);

      // Update local state
      setUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user))
      );

      // Display success message
      setSuccessMessage('User updated successfully.');
    } catch (error) {
      console.error('Error updating user:', error);
      setErrorMessage('Failed to update user. Please try again.');
      throw error; // Re-throw the error to let the modal handle it
    }
  };

  /**
   * Handles deleting a user.
   * @param {Object} userToDelete - The user object to delete.
   */
  const handleDeleteUser = async (userToDelete) => {
    if (!userToDelete.id) {
      setErrorMessage('User ID is missing.');
      return;
    }

    try {
      // Delete user from Firestore
      await deleteUserFromFirestore(userToDelete.id);

      // Update local state
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userToDelete.id));

      // Display success message
      setSuccessMessage('User deleted successfully.');

      // Close the UserDetailsModal
      closeUserDetails();
    } catch (error) {
      console.error('Error deleting user:', error);
      setErrorMessage('Failed to delete user. Please try again.');
    }
  };

  if (userLoading || privilegesLoading) {
    return (
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Check if the current user has the privilege to manage users
  if (!currentUser || currentUser.role !== 'Owner') {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">Access denied. Only owners can manage users.</Typography>
      </Box>
    );
  }

  if (privilegesError) {
    return (
      <Box sx={{ padding: 2 }}>
        <Alert severity="error">{privilegesError}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      {/* Success Snackbar */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* General Error Alert */}
      {error && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {error}
        </Alert>
      )}

      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        {/* Search Field */}
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Search Users"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
              style: { height: '56px' },
            }}
          />
        </Grid>

        {/* Role Filter */}
        <Grid item xs={6} sm={3} md={2}>
          <Select
            value={roleFilter}
            onChange={(e) => setRoleFilter(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ height: '56px' }}
            renderValue={(selected) => (selected ? selected : 'All Roles')}
          >
            <MenuItem value="">All Roles</MenuItem>
            <MenuItem value="Owner">Owner</MenuItem>
            <MenuItem value="Mechanic">Mechanic</MenuItem>
            <MenuItem value="Assistant">Assistant</MenuItem>
            {/* Add more roles as needed */}
          </Select>
        </Grid>

        {/* Status Filter */}
        <Grid item xs={6} sm={3} md={2}>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ height: '56px' }}
            renderValue={(selected) => (selected ? selected : 'All Statuses')}
          >
            <MenuItem value="">All Statuses</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
            {/* Add more statuses as needed */}
          </Select>
        </Grid>

        {/* Sorting Field */}
        <Grid item xs={12} sm={6} md={3}>
          <Select
            value={sortField}
            onChange={(e) => setSortField(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ height: '56px' }}
            renderValue={(selected) => (selected ? selected : 'Sort By')}
          >
            <MenuItem value="name">Sort by Name</MenuItem>
            <MenuItem value="createdAt">Sort by Date Added</MenuItem>
            <MenuItem value="role">Sort by Role</MenuItem>
          </Select>
        </Grid>

        {/* Sorting Order */}
        <Grid item xs={6} sm={3} md={1}>
          <Button
            variant="outlined"
            onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
            fullWidth
            sx={{ height: '56px' }}
          >
            {sortOrder === 'asc' ? 'Asc' : 'Desc'}
          </Button>
        </Grid>

        {/* Add User Button */}
        {privileges.createNewUsers && (
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                justifyContent: 'space-between',
                alignItems: 'stretch',
                height: '56px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={openAddUserForm}
                sx={{
                  flex: 1,
                }}
              >
                Add New User
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      {loading ? (
        <Box sx={{ textAlign: 'center', marginTop: 4 }}>
          <CircularProgress />
        </Box>
      ) : users.length > 0 ? (
        isMobile ? (
          // Mobile View: Detailed List
          <List>
            {users.map((user) => (
              <ListItem
                key={user.id}
                button
                onClick={() => openUserDetails(user)}
                divider
              >
                <ListItemText
                  primary={user.name}
                  secondary={
                    <>
                      <Typography variant="body2" color="textPrimary">
                        Email: {user.email || 'N/A'}
                      </Typography>
                      <Typography variant="body2" color="textPrimary">
                        Role: {user.role || 'N/A'}
                      </Typography>
                      <Typography variant="body2" color="textPrimary">
                        Status: {user.status || 'N/A'}
                      </Typography>
                      <Typography variant="body2" color="textPrimary">
                        Date Added: {user.createdAt ? new Date(user.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}
                      </Typography>
                      {/* Display privileges if privileges.viewPrivileges */}
                      {privileges.viewPrivileges && (
                        <>
                          <Typography variant="body2" color="textPrimary">
                            Privileges:
                          </Typography>
                          {Object.entries(user.privileges || {}).map(([priv, isGranted]) => (
                            isGranted && (
                              <Typography key={priv} variant="caption" color="textSecondary">
                                • {priv.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                              </Typography>
                            )
                          ))}
                          {(!user.privileges || Object.keys(user.privileges).length === 0) && (
                            <Typography variant="caption" color="textSecondary">
                              No privileges assigned.
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          // Desktop View: Table
          <TableContainer component={Paper}>
            <Table aria-label="users table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('name')}
                      endIcon={
                        sortField === 'name' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Name
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('email')}
                      endIcon={
                        sortField === 'email' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Email
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('role')}
                      endIcon={
                        sortField === 'role' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Role
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('status')}
                      endIcon={
                        sortField === 'status' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Status
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('createdAt')}
                      endIcon={
                        sortField === 'createdAt' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Date Added
                    </Button>
                  </TableCell>
                  {privileges.viewPrivileges && (
                    <TableCell>
                      Privileges
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow
                    key={user.id}
                    hover
                    onClick={() => openUserDetails(user)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email || 'N/A'}</TableCell>
                    <TableCell>{user.role || 'N/A'}</TableCell>
                    <TableCell>{user.status || 'N/A'}</TableCell>
                    <TableCell>
                      {user.createdAt
                        ? new Date(user.createdAt.seconds * 1000).toLocaleDateString()
                        : 'N/A'}
                    </TableCell>
                    {privileges.viewPrivileges && (
                      <TableCell>
                        {Object.entries(user.privileges || {}).map(([priv, isGranted]) => (
                          isGranted ? (
                            <Typography key={priv} variant="body2" color="textSecondary">
                              • {priv.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                            </Typography>
                          ) : null
                        ))}
                        {(!user.privileges || Object.keys(user.privileges).length === 0) && (
                          <Typography variant="body2" color="textSecondary">
                            No privileges assigned.
                          </Typography>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      ) : (
        <Typography>No users found.</Typography>
      )}

      {/* Add User Form Modal */}
      <AddUserForm open={openAddUser} onClose={closeAddUserForm} />

      {/* User Details Modal */}
      {selectedUser && (
        <UserDetailsModal
          user={selectedUser}
          open={Boolean(selectedUser)}
          onClose={closeUserDetails}
          onSave={handleSaveUser} // Passed onSave handler
          onDelete={handleDeleteUser} // Passed onDelete handler
        />
      )}
    </Box>
  );
};

export default UserManagement;
