// src/components/Sidebar.jsx

import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  IconButton,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
  Drawer,
  useTheme,
  useMediaQuery,
  Box,
  Badge,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import BuildIcon from '@mui/icons-material/Build';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';
import EngineeringIcon from '@mui/icons-material/Engineering';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AdminIcon from '@mui/icons-material/SupervisorAccount';
// import pluginsIcon from '@mui/icons-material/Plugins';
import PluginIcon from '@mui/icons-material/Extension';
import { auth, db } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, onSnapshot, collection, query, where, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';
import { formatDistanceToNow } from 'date-fns'; // For formatting notification timestamps
import { orderBy } from 'firebase/firestore';

const drawerWidth = 240;
const miniDrawerWidth = 60;

// Styled components
const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile',
})(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin-left', {
    easing: theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
}));

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: open ? `${drawerWidth}px` : `${miniDrawerWidth}px`,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    backgroundColor: 'var(--background-color)',
    color: 'var(--text-color)',
    position: 'fixed',
    top: 64, // Adjust to match the AppBar height
    height: 'calc(100% - 64px)',
    width: open ? `${drawerWidth}px` : `${miniDrawerWidth}px`,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: open
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
  },
}));

// Enhanced menuItems with required privileges
const allMenuItems = [
  { text: 'Home', icon: <HomeIcon />, path: '/', privilege: null },
  { text: 'Jobs', icon: <BuildIcon />, path: '/jobs', privilege: 'viewJobs' },
  { text: 'Customers', icon: <PeopleAltIcon />, path: '/customers', privilege: 'viewCustomers' },
  { text: 'Users', icon: <EngineeringIcon />, path: '/users', privilege: 'viewUsers' },
  { text: 'Plugins', icon: <PluginIcon />, path: '/plugins', privilege: 'modifyPlugins' },
  { text: 'Admin', icon: <AdminIcon />, path: '/admin', privilege: 'admin' },
];


const Sidebar = ({ children }) => {
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationsEl, setNotificationsEl] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const [avatarUrl, setAvatarUrl] = useState('');
  const navigate = useNavigate();

  const hideAppBarPaths = ['/auth'];
  const shouldHideAppBar = hideAppBarPaths.includes(location.pathname);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // State for privileges
  const [privileges, setPrivileges] = useState([]);

  // State for notifications
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  // Fetch user privileges
  useEffect(() => {
    let unsubscribe;
    const fetchPrivileges = () => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        unsubscribe = onSnapshot(
          userDocRef,
          (docSnap) => {
            if (docSnap.exists()) {
              const data = docSnap.data();
              const userPrivileges = data.privileges || {};
              const privs = Object.keys(userPrivileges).filter((priv) => userPrivileges[priv]);
              console.log('Fetched Privileges:', privs); // Debugging
              setPrivileges(privs);
            } else {
              console.log('User document does not exist.');
              setPrivileges([]);
            }
          },
          (err) => {
            console.error('Error fetching privileges:', err);
            setPrivileges([]);
          }
        );
      }
    };

    fetchPrivileges();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [user]);

  // Fetch user avatar
  useEffect(() => {
    let unsubscribe;

    const fetchAvatarRealTime = () => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        unsubscribe = onSnapshot(
          userDocRef,
          (docSnap) => {
            if (docSnap.exists()) {
              const data = docSnap.data();
              setAvatarUrl(data.avatarUrl || '');
            } else {
              setAvatarUrl('');
            }
          },
          (err) => {
            console.error('Error fetching user avatar:', err);
            setAvatarUrl('');
          }
        );
      }
    };

    fetchAvatarRealTime();

    // Cleanup subscription on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);

  // Fetch notifications
  useEffect(() => {
    let unsubscribe;
  
    const fetchNotifications = () => {
      if (user) {
        const notificationsRef = collection(db, 'notifications');
        const qNotifications = query(
          notificationsRef,
          where('userId', '==', user.uid),
          where('read', '==', false),
          orderBy('timestamp', 'desc')
        );
  
        unsubscribe = onSnapshot(
          qNotifications,
          (snapshot) => {
            const notifs = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            console.log('Fetched Notifications:', notifs); // Debugging
            setNotifications(notifs);
            setUnreadCount(notifs.length);
          },
          (err) => {
            console.error('Error fetching notifications:', err);
            setNotifications([]);
            setUnreadCount(0);
          }
        );
      }
    };
  
    fetchNotifications();
  
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);  

  // Handle Drawer Toggle
  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Handle User Menu
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsMenu = (event) => {
    setNotificationsEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNotificationsEl(null);
  };

  // Handle marking a notification as read
  const handleMarkAsRead = async (notifId) => {
    try {
      const notifRef = doc(db, 'notifications', notifId);
      await updateDoc(notifRef, { read: true });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  // Filter menu items based on privileges
  const allowedMenuItems = allMenuItems.filter((item) => {
    if (!item.privilege) return true; // No privilege required
    return privileges.includes(item.privilege);
  });

  if (loading) {
    // Optionally, you can return a loading indicator while fetching user state
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      {/* AppBar */}
      {!shouldHideAppBar && (
        <AppBar position="fixed" style={{ backgroundColor: 'var(--primary-color)' }}>
          <Toolbar>
            {/* Hamburger Menu */}
            <IconButton
              color="inherit"
              aria-label="toggle drawer"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{
                marginRight: '16px',
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* Title */}
            <Typography variant="h6" noWrap sx={{ flexGrow: 1, fontWeight: 700 }}>
              Mechanic Manager
            </Typography>
            {/* Notifications and User Menu */}
            {user && (
              <div>
                <Tooltip title="Notifications">
                  <IconButton color="inherit" onClick={handleNotificationsMenu} size="large">
                    <Badge badgeContent={unreadCount} color="error">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={notificationsEl}
                  open={Boolean(notificationsEl)}
                  onClose={handleClose}
                  sx={{ mt: 2 }}
                >
                  {notifications.length > 0 ? (
                    notifications.map((notif) => (
                      <MenuItem
                        key={notif.id}
                        onClick={() => {
                          handleMarkAsRead(notif.id);
                          handleClose();
                          // Optionally, navigate to a specific page based on notification
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                          <Typography variant="body2">{notif.message}</Typography>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{ marginTop: '4px' }}
                          >
                            {notif.timestamp
                              ? formatDistanceToNow(notif.timestamp.toDate(), { addSuffix: true })
                              : ''}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem onClick={handleClose}>
                      <Typography variant="body2">No new notifications</Typography>
                    </MenuItem>
                  )}
                </Menu>
                <Tooltip title="User Menu">
                  <IconButton edge="end" color="inherit" onClick={handleMenu}>
                    <Avatar alt={user.displayName || 'User'} src={avatarUrl || ''}>
                      {!avatarUrl && user.displayName ? user.displayName.charAt(0) : ''}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{ mt: 1 }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate('/profile', { replace: true });
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      auth.signOut();
                      handleClose();
                      navigate('/auth', { replace: true });
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      )}
      {/* Sidebar Drawer */}
      {!shouldHideAppBar && (
        <>
          {isMobile ? (
            <Drawer
              variant="temporary"
              open={open}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Box
                sx={{
                  width: drawerWidth,
                  bgcolor: 'var(--background-color)',
                  height: '100%',
                }}
              >
                {/* User Avatar at the top */}
                {/* <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: theme.spacing(2),
                  }}
                >
                  <Avatar
                    sx={{ width: 80, height: 80 }}
                    src={avatarUrl || ''}
                    alt={user.displayName || 'User'}
                  >
                    {!avatarUrl && user.displayName ? user.displayName.charAt(0) : ''}
                  </Avatar>
                </Box> */}
                <Divider />
                <List>
                  {allowedMenuItems.map((item) => (
                    <ListItem
                      button
                      key={item.text}
                      onClick={() => handleNavigation(item.path)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: isMobile ? '12px' : '8px',
                        minHeight: isMobile ? '60px' : '48px',
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color: 'var(--primary-color)',
                          minWidth: isMobile ? '48px' : '40px',
                          justifyContent: 'center',
                          '& .MuiSvgIcon-root': {
                            fontSize: isMobile ? '1.8rem' : '1.5rem',
                          },
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{
                          fontWeight: 600,
                          color: 'grey',
                          fontSize: isMobile ? '1.2rem' : '1rem',
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
                <Divider />
              </Box>
            </Drawer>
          ) : (
            <StyledDrawer variant="permanent" open={open}>
              {/* User Avatar at the top */}
              {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: open ? 'center' : 'center',
                  alignItems: 'center',
                  padding: theme.spacing(2),
                }}
              >
                <Avatar
                  sx={{ width: 60, height: 60 }}
                  src={avatarUrl || ''}
                  alt={user.displayName || 'User'}
                >
                  {!avatarUrl && user.displayName ? user.displayName.charAt(0) : ''}
                </Avatar>
              </Box> */}
              <Divider />
              <List>
                {allowedMenuItems.map((item) => (
                  <ListItem
                    button
                    key={item.text}
                    onClick={() => handleNavigation(item.path)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px',
                      minHeight: '48px',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: 'var(--primary-color)',
                        minWidth: '40px',
                        justifyContent: 'center',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    {open && (
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          fontSize: '1rem',
                          color: 'grey',
                        }}
                      >
                        {item.text}
                      </Typography>
                    )}
                  </ListItem>
                ))}
              </List>
              <Divider />
            </StyledDrawer>
          )}
        </>
      )}

      {/* Main Content */}
      <Main open={open} isMobile={isMobile}>
        {!shouldHideAppBar && <Toolbar />}
        {children}
      </Main>
    </div>
  );

  // Function to navigate and close drawer on mobile
  function handleNavigation(path) {
    navigate(path);
    if (isMobile) {
      setOpen(false); // Close drawer on mobile after navigation
    }
  }
};

export default Sidebar;
