// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Auth from './components/Auth';
import PrivateRoute from './components/PrivateRoute';
import Home from './pages/Home';
import JobManagement from './pages/JobManagement';
import CustomerManagement from './pages/CustomerManagement';
import UserManagement from './pages/UserManagement';
import Profile from './pages/Profile';
import Plugins from './pages/Plugins';
import Admin from './pages/Admin';

function AppContent() {
  const location = useLocation();
  const hideSidebarPaths = ['/auth'];

  const shouldHideSidebar = hideSidebarPaths.includes(location.pathname);

  return (
    <div className="App">
      {shouldHideSidebar ? (
        <Routes>
          <Route path="/auth" element={<Auth />} />
        </Routes>
      ) : (
        <Sidebar>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/jobs"
              element={
                <PrivateRoute>
                  <JobManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/customers"
              element={
                <PrivateRoute>
                  <CustomerManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <UserManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/plugins"
              element={
                <PrivateRoute>
                  <Plugins />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
          </Routes>
        </Sidebar>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
