// src/components/form/AddCustomerForm.jsx

import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  Paper,
  Divider,
  Alert,
  TableContainer,
} from '@mui/material';
import { collection, addDoc, serverTimestamp, doc, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase';
import useCurrentUserWithShop from '../../hooks/useCurrentUserWithShop';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const AddCustomerForm = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [address, setAddress] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [formSuccess, setFormSuccess] = useState('');
  const { currentUser, loading: userLoading } = useCurrentUserWithShop();

  // Initialize state for newVehicle
  const [newVehicle, setNewVehicle] = useState({
    make: '',
    model: '',
    year: '',
    rego: '',
  });

  // Function to handle form submission
  const handleSubmit = async () => {
    // Input validation for customer fields
    if (!name.trim() || !email.trim() || !contactNumber.trim()) {
      setFormError('Please fill in all required fields (Name, Email, Contact Number).');
      return;
    }

    // Validate each vehicle entry: all fields must be filled
    const validVehicles = vehicles.filter(
      (vehicle) =>
        vehicle.make.trim() &&
        vehicle.model.trim() &&
        vehicle.year.trim() &&
        vehicle.rego.trim()
    );

    if (vehicles.length > 0 && validVehicles.length !== vehicles.length) {
      setFormError('Please ensure all vehicle fields are filled or remove incomplete vehicles.');
      return;
    }

    if (userLoading) {
      setFormError('User information is still loading. Please try again shortly.');
      return;
    }

    if (!currentUser || !currentUser.shopId) {
      setFormError('Unable to determine shop ID. Please ensure you are logged in.');
      return;
    }

    setLoading(true);
    setFormError('');
    setFormSuccess('');

    try {
      const shopId = currentUser.shopId; // Get the current user's shopId

      // Generate searchField for customer
      const searchField = `${name.trim()} ${email.trim()} ${contactNumber.trim()}`.toLowerCase();

      // Add the customer and get the generated ID
      const customerRef = await addDoc(collection(db, 'customers'), {
        name: name.trim(),
        email: email.trim(),
        contactNumber: contactNumber.trim(),
        address: address.trim(),
        shopId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        searchField,
      });

      // Add vehicles if any
      if (validVehicles.length > 0) {
        const batch = writeBatch(db);
        validVehicles.forEach((vehicle) => {
          const vehicleRef = doc(collection(db, 'customers', customerRef.id, 'vehicles'));
          batch.set(vehicleRef, {
            make: vehicle.make.trim(),
            model: vehicle.model.trim(),
            year: vehicle.year.trim(),
            rego: vehicle.rego.trim(),
          });
        });
        await batch.commit();
      }

      setLoading(false);
      setFormSuccess('Customer added successfully.');
      // Reset form fields
      setName('');
      setEmail('');
      setContactNumber('');
      setAddress('');
      setVehicles([]);
      setNewVehicle({
        make: '',
        model: '',
        year: '',
        rego: '',
      });
      onClose();
    } catch (error) {
      console.error('Error adding customer: ', error);
      setLoading(false);
      setFormError('Error adding customer. Please try again.');
    }
  };

  // Function to add a new vehicle to the vehicles array
  const addVehicleToList = () => {
    // Prevent adding if any field in newVehicle is empty
    const { make, model, year, rego } = newVehicle;
    if (!make.trim() || !model.trim() || !year.trim() || !rego.trim()) {
      setFormError('Please fill in all fields to add a vehicle.');
      return;
    }

    // Add the new vehicle to the list
    setVehicles([...vehicles, { make: make.trim(), model: model.trim(), year: year.trim(), rego: rego.trim() }]);
    // Reset newVehicle state
    setNewVehicle({
      make: '',
      model: '',
      year: '',
      rego: '',
    });
    setFormError('');
  };

  // Function to remove a vehicle from the vehicles array
  const removeVehicleFromList = (index) => {
    const updatedVehicles = [...vehicles];
    updatedVehicles.splice(index, 1);
    setVehicles(updatedVehicles);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: 'white',
          padding: 4,
          margin: 'auto',
          marginTop: '2%',
          width: { xs: '95%', sm: '90%', md: '80%' },
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Add New Customer
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />

        {/* Display Form Error */}
        {formError && (
          <Alert
            severity="error"
            onClose={() => setFormError('')}
            sx={{ marginBottom: 2 }}
          >
            {formError}
          </Alert>
        )}

        {/* Display Form Success */}
        {formSuccess && (
          <Alert
            severity="success"
            onClose={() => setFormSuccess('')}
            sx={{ marginBottom: 2 }}
          >
            {formSuccess}
          </Alert>
        )}

        {/* Customer Information Section */}
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Customer Information
        </Typography>
        <Paper elevation={2} sx={{ padding: 2, marginBottom: 3 }}>
          <Grid container spacing={2}>
            {/* Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            {/* Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            {/* Contact Number */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact Number"
                variant="outlined"
                fullWidth
                required
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </Grid>
            {/* Address */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Vehicles Section */}
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Vehicles
        </Typography>
        <Paper elevation={2} sx={{ padding: 2, marginBottom: 3 }}>
          {/* Vehicles Table */}
          {vehicles.length > 0 ? (
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Year</th>
                    <th>Rego</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicles.map((vehicle, index) => (
                    <tr key={index}>
                      <td>{vehicle.make}</td>
                      <td>{vehicle.model}</td>
                      <td>{vehicle.year}</td>
                      <td>{vehicle.rego}</td>
                      <td>
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => removeVehicleFromList(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          ) : (
            <Typography>No vehicles added yet.</Typography>
          )}

          {/* Add Vehicle Form */}
          <Box sx={{ marginTop: 4 }}>
            <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
              Add New Vehicle
            </Typography>
            <Grid container spacing={2} alignItems="center">
              {/* Make */}
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Make"
                  variant="outlined"
                  fullWidth
                  required
                  value={newVehicle.make}
                  onChange={(e) => setNewVehicle({ ...newVehicle, make: e.target.value })}
                />
              </Grid>
              {/* Model */}
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Model"
                  variant="outlined"
                  fullWidth
                  required
                  value={newVehicle.model}
                  onChange={(e) => setNewVehicle({ ...newVehicle, model: e.target.value })}
                />
              </Grid>
              {/* Year */}
              <Grid item xs={12} sm={2}>
                <TextField
                  label="Year"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  inputProps={{ min: '1886', max: new Date().getFullYear() + 1 }}
                  value={newVehicle.year}
                  onChange={(e) => setNewVehicle({ ...newVehicle, year: e.target.value })}
                />
              </Grid>
              {/* Rego */}
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Rego"
                  variant="outlined"
                  fullWidth
                  required
                  value={newVehicle.rego}
                  onChange={(e) => setNewVehicle({ ...newVehicle, rego: e.target.value })}
                />
              </Grid>
              {/* Add Button */}
              <Grid item xs={12} sm={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  aria-label="add vehicle"
                  color="primary"
                  onClick={addVehicleToList}
                  disabled={loading}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        {/* Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
          <Button onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading || userLoading}
          >
            {loading ? 'Adding...' : 'Add Customer'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddCustomerForm;
