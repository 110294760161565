// src/pages/Admin.jsx

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { collection, addDoc, updateDoc, deleteDoc, query, where, getDocs, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import path as necessary
import useCurrentUserWithShop from '../hooks/useCurrentUserWithShop';
import { serverTimestamp, orderBy } from 'firebase/firestore';

const Admin = () => {
  const { currentUser, loading: userLoading } = useCurrentUserWithShop();
  const shopId = currentUser?.shopId;

  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  // States for Add/Edit Service Modal
  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentService, setCurrentService] = useState({ id: '', name: '', defaultPrice: '' });

  // State for Delete Confirmation Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  // Fetch services from Firestore
  useEffect(() => {
    const fetchServices = async () => {
      if (!shopId) return;
      setLoadingServices(true);
      setError(null);
      try {
        const servicesRef = collection(db, 'services');
        const q = query(servicesRef, where('shopId', '==', shopId), orderBy('name'));
        const querySnapshot = await getDocs(q);
        const servicesList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServices(servicesList);
      } catch (err) {
        console.error('Error fetching services:', err);
        setError('Failed to fetch services.');
      }
      setLoadingServices(false);
    };

    fetchServices();
  }, [shopId]);

  // Handle opening the Add Service Modal
  const handleOpenAddModal = () => {
    setIsEditMode(false);
    setCurrentService({ id: '', name: '', defaultPrice: '' });
    setOpenModal(true);
  };

  // Handle opening the Edit Service Modal
  const handleOpenEditModal = (service) => {
    setIsEditMode(true);
    setCurrentService({
      id: service.id,
      name: service.name,
      defaultPrice: service.defaultPrice,
    });
    setOpenModal(true);
  };

  // Handle closing the Add/Edit Modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentService({ id: '', name: '', defaultPrice: '' });
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentService((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle submitting the Add/Edit form
  const handleSubmit = async () => {
    const { id, name, defaultPrice } = currentService;

    // Validation
    if (!name.trim()) {
      setError('Service name is required.');
      return;
    }
    if (!defaultPrice || isNaN(defaultPrice) || parseFloat(defaultPrice) < 0) {
      setError('Please enter a valid default price.');
      return;
    }

    setError(null);
    setLoadingServices(true);

    try {
      if (isEditMode) {
        // Edit existing service
        const serviceRef = doc(db, 'services', id);
        await updateDoc(serviceRef, {
          name: name.trim(),
          defaultPrice: parseFloat(defaultPrice),
          updatedAt: serverTimestamp(),
        });
        setSuccessMessage('Service updated successfully.');
      } else {
        // Add new service
        await addDoc(collection(db, 'services'), {
          name: name.trim(),
          defaultPrice: parseFloat(defaultPrice),
          shopId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        setSuccessMessage('Service added successfully.');
      }

      // Refresh services list
      const servicesRef = collection(db, 'services');
      const q = query(servicesRef, where('shopId', '==', shopId), orderBy('name'));
      const querySnapshot = await getDocs(q);
      const servicesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesList);
      handleCloseModal();
    } catch (err) {
      console.error('Error adding/updating service:', err);
      setError('Failed to add/update service.');
    }
    setLoadingServices(false);
  };

  // Handle opening the Delete Confirmation Dialog
  const handleOpenDeleteDialog = (service) => {
    setServiceToDelete(service);
    setOpenDeleteDialog(true);
  };

  // Handle confirming deletion
  const handleConfirmDelete = async () => {
    if (!serviceToDelete) return;
    setError(null);
    setLoadingServices(true);
    try {
      const serviceRef = doc(db, 'services', serviceToDelete.id);
      await deleteDoc(serviceRef);
      setSuccessMessage('Service deleted successfully.');

      // Refresh services list
      const servicesRef = collection(db, 'services');
      const q = query(servicesRef, where('shopId', '==', shopId), orderBy('name'));
      const querySnapshot = await getDocs(q);
      const servicesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesList);
      setOpenDeleteDialog(false);
      setServiceToDelete(null);
    } catch (err) {
      console.error('Error deleting service:', err);
      setError('Failed to delete service.');
    }
    setLoadingServices(false);
  };

  // Handle cancelling deletion
  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
    setServiceToDelete(null);
  };

  // Render the Admin Page
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Admin Panel
      </Typography>

      {/* Display Success Message */}
      {successMessage && (
        <Alert
          severity="success"
          onClose={() => setSuccessMessage('')}
          sx={{ marginBottom: 2 }}
        >
          {successMessage}
        </Alert>
      )}

      {/* Display Error Message */}
      {error && (
        <Alert
          severity="error"
          onClose={() => setError(null)}
          sx={{ marginBottom: 2 }}
        >
          {error}
        </Alert>
      )}

      {/* Add Service Button */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={handleOpenAddModal}
        sx={{ marginBottom: 2 }}
      >
        Add New Service
      </Button>

      {/* Services Table */}
      <TableContainer component={Paper}>
        <Table aria-label="services table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Service Name</strong></TableCell>
              <TableCell><strong>Default Price ($)</strong></TableCell>
              <TableCell align="center"><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingServices ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : services.length > 0 ? (
              services.map((service) => (
                <TableRow key={service.id}>
                  <TableCell>{service.name}</TableCell>
                  <TableCell>{service.defaultPrice.toFixed(2)}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      aria-label="edit"
                      color="primary"
                      onClick={() => handleOpenEditModal(service)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      color="error"
                      onClick={() => handleOpenDeleteDialog(service)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No services found. Click "Add New Service" to create one.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add/Edit Service Modal */}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>{isEditMode ? 'Edit Service' : 'Add New Service'}</DialogTitle>
        <DialogContent>
          <Box sx={{ marginTop: 2 }}>
            <TextField
              label="Service Name"
              name="name"
              variant="outlined"
              fullWidth
              required
              value={currentService.name}
              onChange={handleInputChange}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Default Price ($)"
              name="defaultPrice"
              variant="outlined"
              fullWidth
              required
              type="number"
              inputProps={{ step: 0.01, min: 0 }}
              value={currentService.defaultPrice}
              onChange={handleInputChange}
              sx={{ marginBottom: 2 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loadingServices}
          >
            {loadingServices ? <CircularProgress size={24} /> : isEditMode ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCancelDelete}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Delete Service</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the service "{serviceToDelete?.name}"? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmDelete}
            disabled={loadingServices}
          >
            {loadingServices ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Admin;
