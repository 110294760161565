// TimeTracker.jsx
import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Button, Typography } from '@mui/material';

const TimeTracker = ({ jobId, estimatedTime }) => {
  const [isTracking, setIsTracking] = useState(false);
  const [actualTime, setActualTime] = useState(0);
  const [timerId, setTimerId] = useState(null);

  const handleStart = () => {
    setIsTracking(true);
    const startTime = Date.now() - actualTime;
    const id = setInterval(() => {
      setActualTime(Date.now() - startTime);
    }, 1000);
    setTimerId(id);
  };

  const handleStop = async () => {
    setIsTracking(false);
    clearInterval(timerId);
    const jobRef = doc(db, 'jobs', jobId);
    await updateDoc(jobRef, {
      actualTime: actualTime,
    });
  };

  return (
    <div>
      <Typography variant="h6">Time Tracking</Typography>
      <Typography variant="body1">Estimated Time: {estimatedTime} hours</Typography>
      <Typography variant="body1">
        Actual Time: {(actualTime / 3600000).toFixed(2)} hours
      </Typography>
      {!isTracking ? (
        <Button variant="contained" color="primary" onClick={handleStart}>
          Start
        </Button>
      ) : (
        <Button variant="contained" color="secondary" onClick={handleStop}>
          Stop
        </Button>
      )}
    </div>
  );
};

export default TimeTracker;
