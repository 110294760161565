// src/pages/ProfilePage.jsx

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Avatar,
  Paper,
  Grid,
  Button,
  IconButton,
  Alert,
  TextField,
} from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, storage } from '../firebase';
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { updateEmail, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';

const Profile = () => {
  // Hook to get the current authenticated user
  const [user, loading, error] = useAuthState(auth);

  // State to hold user profile data
  const [profileData, setProfileData] = useState(null);
  const [profileLoading, setProfileLoading] = useState(true);
  const [profileError, setProfileError] = useState(null);

  // State for image upload
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // State for editing profile
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [editPassword, setEditPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [editError, setEditError] = useState(null);
  const [editSuccess, setEditSuccess] = useState(null);
  const [updatingProfile, setUpdatingProfile] = useState(false);

  useEffect(() => {
    // Fetch user profile data when authenticated
    if (user) {
      const fetchProfile = async () => {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setProfileData(userDoc.data());
            setEditName(userDoc.data().name || '');
            setEditEmail(user.email || '');
          } else {
            setProfileError('No profile data found.');
          }
        } catch (err) {
          setProfileError(err.message);
        }
        setProfileLoading(false);
      };
      fetchProfile();
    } else {
      setProfileLoading(false);
    }
  }, [user]);

  // Handle profile picture upload
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setUploadError('Please select a valid image file.');
      return;
    }

    // Optional: Validate file size (e.g., max 5MB)
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSizeInBytes) {
      setUploadError('Image size should not exceed 5MB.');
      return;
    }

    setUploading(true);
    setUploadError(null);
    setSuccessMessage(null);

    try {
      // Create a unique file name
      const fileRef = ref(storage, `avatars/${user.uid}/${Date.now()}_${file.name}`);

      // Upload the file
      await uploadBytes(fileRef, file);

      // Get the download URL
      const downloadURL = await getDownloadURL(fileRef);

      // Update the user's Firestore document with the new avatarUrl
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        avatarUrl: downloadURL,
        updatedAt: serverTimestamp(),
      });

      // Update local state
      setProfileData((prevData) => ({
        ...prevData,
        avatarUrl: downloadURL,
      }));

      setSuccessMessage('Profile picture updated successfully!');
    } catch (err) {
      console.error('Error uploading image:', err);
      setUploadError('Failed to upload image. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  // Handle enabling edit mode
  const handleEdit = () => {
    setIsEditing(true);
    setEditError(null);
    setEditSuccess(null);
    setEditPassword('');
    setCurrentPassword('');
  };

  // Handle canceling edit mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditError(null);
    setEditSuccess(null);
    setEditPassword('');
    setCurrentPassword('');
    // Reset edit fields to current profile data
    setEditName(profileData?.name || '');
    setEditEmail(user.email || '');
  };

  // Handle saving profile changes
  const handleSaveProfile = async () => {
    setEditError(null);
    setEditSuccess(null);

    // Basic validation
    if (!editName.trim()) {
      setEditError('Name cannot be empty.');
      return;
    }

    if (!editEmail.trim()) {
      setEditError('Email cannot be empty.');
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(editEmail)) {
      setEditError('Please enter a valid email address.');
      return;
    }

    // If user is changing email or password, require current password
    const isUpdatingSensitiveInfo = editEmail !== user.email || editPassword;

    if (isUpdatingSensitiveInfo && !currentPassword) {
      setEditError('Please enter your current password to confirm changes.');
      return;
    }

    // Password strength validation (if password is being updated)
    if (editPassword && editPassword.length < 6) {
      setEditError('Password should be at least 6 characters long.');
      return;
    }

    setUpdatingProfile(true);

    try {
      // Re-authenticate the user if updating sensitive information
      if (isUpdatingSensitiveInfo) {
        const credential = EmailAuthProvider.credential(user.email, currentPassword);
        await reauthenticateWithCredential(user, credential);
      }

      // Update Name in Firestore
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        name: editName,
        updatedAt: serverTimestamp(),
      });

      // Update Email in Firebase Auth (if changed)
      if (editEmail !== user.email) {
        await updateEmail(user, editEmail);
      }

      // Update Password in Firebase Auth (if provided)
      if (editPassword) {
        await updatePassword(user, editPassword);
      }

      // Update local state
      setProfileData((prevData) => ({
        ...prevData,
        name: editName,
      }));

      // If email was changed, Firebase Auth's user object should update automatically
      setEditSuccess('Profile updated successfully!');
      setIsEditing(false);
    } catch (err) {
      console.error('Error updating profile:', err);
      // Handle specific error codes
      if (err.code === 'auth/wrong-password') {
        setEditError('Incorrect current password. Please try again.');
      } else if (err.code === 'auth/requires-recent-login') {
        setEditError('Please log in again to update your profile.');
      } else if (err.code === 'auth/email-already-in-use') {
        setEditError('The email address is already in use by another account.');
      } else {
        setEditError(err.message || 'Failed to update profile.');
      }
    } finally {
      setUpdatingProfile(false);
    }
  };

  // Display loading indicator while authenticating or fetching profile
  if (loading || profileLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Display error message if authentication or fetching profile fails
  if (error || profileError) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          px: 2, // Padding for small screens
        }}
      >
        <Typography variant="h6" color="error" textAlign="center">
          {error?.message || profileError}
        </Typography>
      </Box>
    );
  }

  // Prompt user to log in if not authenticated
  if (!user) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          px: 2, // Padding for small screens
        }}
      >
        <Typography variant="h6" textAlign="center">
          You are not logged in. Please log in to view your profile.
        </Typography>
      </Box>
    );
  }

  // Render user profile information
  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: 'auto',
        mt: 4,
        p: 2,
      }}
    >
      <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 } }}>
        <Grid
          container
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
        >
          {/* Avatar and Basic Info */}
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', sm: 'flex-start' },
            }}
          >
            <Avatar
              sx={{
                width: { xs: 100, sm: 120 },
                height: { xs: 100, sm: 120 },
                mb: { xs: 2, sm: 0 },
              }}
              src={profileData?.avatarUrl || ''}
              alt={profileData?.name || 'User'}
            />
            {/* Upload Button */}
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
              sx={{ mt: { sm: 2 }, width: { xs: '100%', sm: 'auto' } }}
              disabled={uploading}
            >
              {uploading ? 'Uploading...' : 'Change Picture'}
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleImageUpload}
              />
            </Button>
            {/* Success and Error Messages */}
            {successMessage && (
              <Alert severity="success" sx={{ mt: 2, width: '100%' }}>
                {successMessage}
              </Alert>
            )}
            {uploadError && (
              <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                {uploadError}
              </Alert>
            )}
          </Grid>

          {/* Profile Details */}
          <Grid item xs={12} sm={8}>
            {/* Display Success and Error Messages for Edit */}
            {editSuccess && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {editSuccess}
              </Alert>
            )}
            {editError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {editError}
              </Alert>
            )}

            {/* View Mode */}
            {!isEditing && (
              <>
                <Grid container spacing={2}>
                  {/* Name */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" color="textSecondary">
                      Name
                    </Typography>
                    <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                      {profileData?.name || 'N/A'}
                    </Typography>
                  </Grid>

                  {/* Email */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" color="textSecondary">
                      Email
                    </Typography>
                    <Typography variant="body1">{user.email}</Typography>
                  </Grid>

                  {/* Phone */}
                  {profileData?.phone && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Phone
                      </Typography>
                      <Typography variant="body1">{profileData.phone}</Typography>
                    </Grid>
                  )}

                  {/* Address */}
                  {profileData?.address && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Address
                      </Typography>
                      <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                        {profileData.address}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                {/* Edit Button */}
                <Box sx={{ textAlign: 'right', mt: 2 }}>
                  <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={handleEdit}
                  >
                    Edit Profile
                  </Button>
                </Box>
              </>
            )}

            {/* Edit Mode */}
            {isEditing && (
              <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  {/* Name */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      required
                    />
                  </Grid>

                  {/* Email */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      type="email"
                      value={editEmail}
                      onChange={(e) => setEditEmail(e.target.value)}
                      required
                    />
                  </Grid>

                  {/* Current Password */}
                  {isUpdatingSensitiveInfo() && (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Current Password"
                        variant="outlined"
                        fullWidth
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                        helperText="Enter your current password to confirm changes."
                      />
                    </Grid>
                  )}

                  {/* Password */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="New Password"
                      variant="outlined"
                      fullWidth
                      type="password"
                      value={editPassword}
                      onChange={(e) => setEditPassword(e.target.value)}
                      helperText="Leave blank to keep current password"
                    />
                  </Grid>
                </Grid>

                {/* Action Buttons */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSaveProfile}
                    disabled={updatingProfile}
                    sx={{ mr: 2 }}
                  >
                    {updatingProfile ? 'Saving...' : 'Save'}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={handleCancelEdit}
                    disabled={updatingProfile}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );

  // Function to determine if sensitive information is being updated
  function isUpdatingSensitiveInfo() {
    return editEmail !== user.email || editPassword;
  }
};

export default Profile;
