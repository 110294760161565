// src/components/modal/UserDetailsModal.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
  IconButton,
  TextField,
  MenuItem,
  Alert,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  InputLabel,
  Select,
  DialogContentText,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import useCurrentUserWithShop from '../../hooks/useCurrentUserWithShop';

const availablePrivileges = {
  customers: ['viewCustomers', 'editCustomers'],
  users: ['viewUsers', 'editUsers'],
  jobs: ['viewJobs', 'editJobs'],
  admin: ['admin', 'viewPrivileges', 'modifyPlugins', 'createNewUsers'],
  // Add more categories and privileges as needed
};

const UserDetailsModal = ({ user, open, onClose, onSave, onDelete }) => {
  // State for edit mode and editable user data
  const [isEditing, setIsEditing] = useState(false);
  const [editableUser, setEditableUser] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  // State for current user's privileges
  const { currentUser, loading: userLoading, error: userError } = useCurrentUserWithShop();
  const [hasEditUsers, setHasEditUsers] = useState(false);
  const [hasViewPrivileges, setHasViewPrivileges] = useState(false);
  const [privilegesLoading, setPrivilegesLoading] = useState(true);
  const [privilegesError, setPrivilegesError] = useState(null);

  // State for banner messages
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerSeverity, setBannerSeverity] = useState('success'); // 'success' | 'error'
  const [bannerVisible, setBannerVisible] = useState(false);

  // State for delete confirmation dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Fetch current user's privileges
  useEffect(() => {
    const fetchPrivileges = async () => {
      if (!currentUser) {
        setHasEditUsers(false);
        setHasViewPrivileges(false);
        setPrivilegesLoading(false);
        return;
      }

      try {
        const userData = currentUser;
        const userPrivileges = userData.privileges || {};
        setHasEditUsers(Boolean(userPrivileges.editUsers));
        setHasViewPrivileges(Boolean(userPrivileges.viewPrivileges));
      } catch (error) {
        console.error('Error fetching current user privileges:', error);
        setPrivilegesError('Failed to fetch your privileges.');
      }
      setPrivilegesLoading(false);
    };

    fetchPrivileges();
  }, [currentUser]);

  // Initialize editable user data when modal opens or user changes
  useEffect(() => {
    if (user) {
      setEditableUser(user);
      setPrivileges(user.privileges || {});
      setError(null);
      setIsEditing(false);
    }
  }, [user, open]);

  // Handle input changes for user details
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle privilege checkbox changes
  const handlePrivilegeChange = (event) => {
    const { name, checked } = event.target;
    setPrivileges((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Function to show banner messages
  const showBanner = (message, severity = 'success') => {
    setBannerMessage(message);
    setBannerSeverity(severity);
    setBannerVisible(true);
    setTimeout(() => setBannerVisible(false), 10000); // Hide after 10 seconds
  };

  // Handle save action
  const handleSave = async () => {
    // Prevent saving if user lacks privilege
    if (!hasEditUsers) {
      showBanner('You do not have permission to edit users.', 'error');
      return;
    }

    setError(null);

    // Simple validation (expand as needed)
    if (!editableUser.name.trim()) {
      setError('Name is required.');
      return;
    }

    if (!editableUser.email.trim()) {
      setError('Email is required.');
      return;
    }

    // Additional validation can be added here (e.g., email format)

    setIsSaving(true);

    try {
      await onSave(editableUser, privileges);
      setIsEditing(false);
      showBanner('User details updated successfully.', 'success');
    } catch (saveError) {
      console.error('Error saving user:', saveError);
      setError('Failed to save changes. Please try again.');
      showBanner('Failed to save changes. Please try again.', 'error');
    } finally {
      setIsSaving(false);
      onClose();
    }
  };

  // Handle cancel action
  const handleCancel = () => {
    setEditableUser(user);
    setPrivileges(user.privileges || {});
    setError(null);
    setIsEditing(false);
  };

  // Handle delete user
  const handleDeleteUser = async () => {
    setOpenDeleteDialog(false);
    try {
      await onDelete(user.uid); // Use 'uid' instead of 'id'
      showBanner('User deleted successfully.', 'success');
      onClose();
    } catch (deleteError) {
      console.error('Error deleting user:', deleteError);
      showBanner('Failed to delete user. Please try again.', 'error');
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="user-details-dialog"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="user-details-dialog">
          {isEditing ? 'Edit User Details' : 'User Details'}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* Banner Message */}
          {bannerVisible && (
            <Alert
              severity={bannerSeverity}
              onClose={() => setBannerVisible(false)}
              sx={{ marginBottom: 2 }}
            >
              {bannerMessage}
            </Alert>
          )}

          {/* Privileges Loading/Error */}
          {privilegesLoading && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <CircularProgress size={24} sx={{ marginRight: 2 }} />
              <Typography variant="body2" color="textSecondary">
                Loading privileges...
              </Typography>
            </Box>
          )}
          {privilegesError && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {privilegesError}
            </Alert>
          )}

          {/* Error Message */}
          {error && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {error}
            </Alert>
          )}

          <Grid container spacing={2}>
            {/* Name */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Name
              </Typography>
              {isEditing ? (
                <TextField
                  fullWidth
                  name="name"
                  value={editableUser.name || ''}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  required
                  label="Name"
                />
              ) : (
                <Typography variant="body1">{user.name || 'N/A'}</Typography>
              )}
            </Grid>

            {/* Username */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Username
              </Typography>
              {isEditing ? (
                <TextField
                  fullWidth
                  name="username"
                  value={editableUser.name || ''}
                  onChange={(e) => setEditableUser({ ...editableUser, name: e.target.value })}
                  variant="outlined"
                  size="small"
                  required
                  label="Username"
                />
              ) : (
                <Typography variant="body1">{user.name || 'N/A'}</Typography>
              )}
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Email
              </Typography>
              {isEditing ? (
                <TextField
                  fullWidth
                  name="email"
                  value={editableUser.email || ''}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  type="email"
                  required
                  label="Email"
                />
              ) : (
                <Typography variant="body1">{user.email || 'N/A'}</Typography>
              )}
            </Grid>

            {/* Role */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Role
              </Typography>
              {isEditing ? (
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="edit-role-label">Role</InputLabel>
                  <Select
                    labelId="edit-role-label"
                    name="role"
                    value={editableUser.role || ''}
                    onChange={handleChange}
                    label="Role"
                  >
                    <MenuItem value="Owner">Owner</MenuItem>
                    <MenuItem value="Mechanic">Mechanic</MenuItem>
                    <MenuItem value="Assistant">Assistant</MenuItem>
                    {/* Add more roles as needed */}
                  </Select>
                </FormControl>
              ) : (
                <Typography variant="body1">{user.role || 'N/A'}</Typography>
              )}
            </Grid>

            {/* Status */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Status
              </Typography>
              {isEditing ? (
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="edit-status-label">Status</InputLabel>
                  <Select
                    labelId="edit-status-label"
                    name="status"
                    value={editableUser.status || ''}
                    onChange={handleChange}
                    label="Status"
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Null">Null</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <Typography variant="body1">{user.status || 'N/A'}</Typography>
              )}
            </Grid>

            {/* Date Added */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Date Added
              </Typography>
              <Typography variant="body1">
                {user.createdAt ? user.createdAt.toDate().toLocaleString() : 'N/A'}
              </Typography>
            </Grid>

            {/* Phone */}
            {user.role === 'Mechanic' && user.phone && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Phone
                </Typography>
                {isEditing ? (
                  <TextField
                    fullWidth
                    name="phone"
                    value={editableUser.phone || ''}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    label="Phone"
                  />
                ) : (
                  <Typography variant="body1">{user.phone}</Typography>
                )}
              </Grid>
            )}

            {/* Address */}
            {user.address && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  Address
                </Typography>
                {isEditing ? (
                  <TextField
                    fullWidth
                    name="address"
                    value={editableUser.address || ''}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    label="Address"
                  />
                ) : (
                  <Typography variant="body1">{user.address}</Typography>
                )}
              </Grid>
            )}

            {/* Privileges */}
            {hasViewPrivileges && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Assign Privileges
                </Typography>
                {isEditing ? (
                  <FormControl component="fieldset" variant="standard">
                    <Grid container spacing={2}>
                      {Object.entries(availablePrivileges).map(([category, privs]) => (
                        <Grid item xs={12} sm={6} key={category}>
                          <Typography variant="subtitle2" gutterBottom>
                            {category.charAt(0).toUpperCase() + category.slice(1)}
                          </Typography>
                          <FormGroup>
                            {privs.map((priv) => (
                              <FormControlLabel
                                key={priv}
                                control={
                                  <Checkbox
                                    checked={privileges[priv] || false}
                                    onChange={handlePrivilegeChange}
                                    name={priv}
                                    disabled={!hasEditUsers} // Disable if user lacks 'editUsers' privilege
                                  />
                                }
                                label={
                                  // Convert camelCase to readable format
                                  priv
                                    .replace(/([A-Z])/g, ' $1')
                                    .replace(/^./, (str) => str.toUpperCase())
                                }
                              />
                            ))}
                          </FormGroup>
                        </Grid>
                      ))}
                    </Grid>
                  </FormControl>
                ) : (
                  <Box>
                    {Object.entries(user.privileges || {}).length > 0 ? (
                      Object.entries(user.privileges).map(([priv, isGranted]) =>
                        isGranted ? (
                          <Typography key={priv} variant="body2" color="textPrimary">
                            • {priv.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                          </Typography>
                        ) : null
                      )
                    ) : (
                      <Typography variant="body2" color="textPrimary">
                        No privileges assigned.
                      </Typography>
                    )}
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {isEditing ? (
            <>
              <Button
                onClick={handleSave}
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                disabled={isSaving || !hasEditUsers} // Disable if saving or lacks privilege
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
              <Button
                onClick={handleCancel}
                color="secondary"
                variant="outlined"
                startIcon={<CancelIcon />}
                disabled={isSaving}
              >
                Cancel
              </Button>
              {/* Delete User Button in Edit Mode */}
              {hasEditUsers && (
                <Button
                  onClick={() => setOpenDeleteDialog(true)}
                  color="error"
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  disabled={isSaving}
                >
                  Delete User
                </Button>
              )}
            </>
          ) : hasEditUsers && user.role !== 'Owner' ? (
            <>
              <Button
                onClick={() => setIsEditing(true)}
                color="primary"
                variant="outlined"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
              {/* Delete User Button in View Mode */}
              <Button
                onClick={() => setOpenDeleteDialog(true)}
                color="error"
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Delete User
              </Button>
            </>
          ) : null}
          <Button onClick={onClose} color="secondary" variant="text">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="delete-user-dialog-title"
        aria-describedby="delete-user-dialog-description"
      >
        <DialogTitle id="delete-user-dialog-title">Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-user-dialog-description">
            Are you sure you want to delete this user? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            color="secondary"
            variant="outlined"
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteUser}
            color="error"
            variant="contained"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar
        open={bannerVisible}
        autoHideDuration={6000}
        onClose={() => setBannerVisible(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setBannerVisible(false)}
          severity={bannerSeverity}
          sx={{ width: '100%' }}
        >
          {bannerMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

UserDetailsModal.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired, // Ensure 'uid' is provided
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    status: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    privileges: PropTypes.object,
    createdAt: PropTypes.object, // Firestore Timestamp
    updatedAt: PropTypes.object, // Firestore Timestamp
    shopId: PropTypes.string,
    searchableField: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired, // onSave is required
  onDelete: PropTypes.func.isRequired, // onDelete is required
};

export default UserDetailsModal;
