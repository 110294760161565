// src/components/modal/CustomerDetailsModal.jsx

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Divider,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  IconButton,
} from '@mui/material';
import { 
  doc, 
  updateDoc, 
  serverTimestamp, 
  collection, 
  query, 
  where, 
  getDocs, 
  deleteDoc, 
  addDoc,
  getDoc,
} from 'firebase/firestore';
import { auth, db } from '../../firebase';
import JobDetailsModal from './JobDetailsModal';
import { useAuthState } from 'react-firebase-hooks/auth';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { orderBy, limit } from 'firebase/firestore';

const CustomerDetailsModal = ({ customer, open, onClose }) => {
  // State for customer information
  const [name, setName] = useState(customer.name);
  const [email, setEmail] = useState(customer.email);
  const [contactNumber, setContactNumber] = useState(customer.contactNumber);
  const [address, setAddress] = useState(customer.address);

  // State for jobs
  const [jobs, setJobs] = useState([]);
  const [jobsLoading, setJobsLoading] = useState(true);
  const [jobsError, setJobsError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null); // For JobDetailsModal

  // State for vehicles
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesLoading, setVehiclesLoading] = useState(true);
  const [vehiclesError, setVehiclesError] = useState(null);

  // State for status filter
  const [statusFilter, setStatusFilter] = useState('');

  // State for privileges
  const [hasEditCustomers, setHasEditCustomers] = useState(false);
  const [privilegesLoading, setPrivilegesLoading] = useState(true);
  const [privilegesError, setPrivilegesError] = useState(null);

  // State for banner messages
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerSeverity, setBannerSeverity] = useState('success'); // 'success' | 'error'
  const [bannerVisible, setBannerVisible] = useState(false);

  // State for adding a new vehicle
  const [newVehicle, setNewVehicle] = useState({
    make: '',
    model: '',
    year: '',
    rego: '',
  });
  const [addingVehicle, setAddingVehicle] = useState(false);

  // State for mapping mechanicId to mechanicName
  const [mechanicsMap, setMechanicsMap] = useState({});

  // Get current user
  const [user, userLoading, userError] = useAuthState(auth);

  // Fetch user's privileges
  useEffect(() => {
    const fetchPrivileges = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const userPrivileges = userData.privileges || {};
            setHasEditCustomers(Boolean(userPrivileges.editCustomers));
          } else {
            console.warn('User document does not exist.');
            setHasEditCustomers(false);
          }
        } catch (error) {
          console.error('Error fetching user privileges:', error);
          setPrivilegesError('Failed to fetch user privileges.');
        }
      } else {
        setHasEditCustomers(false);
      }
      setPrivilegesLoading(false);
    };

    fetchPrivileges();
  }, [user]);

  // Fetch jobs whenever customer.id or statusFilter changes
  useEffect(() => {
    const fetchJobs = async () => {
      setJobsLoading(true);
      setJobsError(null);
      try {
        const jobsRef = collection(db, 'jobs');
        let qJobs;

        if (statusFilter) {
          // If a status filter is applied
          qJobs = query(
            jobsRef,
            where('customerId', '==', customer.id),
            where('status', '==', statusFilter),
            // Firestore requires indexing when using multiple where clauses
            // Ensure that you have created the necessary composite indexes
            orderBy('createdAt', 'desc')
          );
        } else {
          // No status filter, fetch all jobs for the customer
          qJobs = query(
            jobsRef,
            where('customerId', '==', customer.id),
            orderBy('createdAt', 'desc')
          );
        }

        const querySnapshot = await getDocs(qJobs);
        const jobList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJobs(jobList);

        // After fetching jobs, fetch mechanics
        await fetchMechanics(jobList);
      } catch (error) {
        console.error('Error fetching jobs:', error);
        setJobsError(`Failed to load jobs: ${error.message}`);
      }
      setJobsLoading(false);
    };

    const fetchMechanics = async (jobList) => {
      const mechanicIds = [
        ...new Set(
          jobList
            .map((job) => job.mechanicId)
            .filter((id) => id) // Remove undefined or null
        ),
      ];

      if (mechanicIds.length === 0) {
        setMechanicsMap({});
        return;
      }

      try {
        const mechanicsPromises = mechanicIds.map((id) => getDoc(doc(db, 'users', id)));
        const mechanicsSnapshots = await Promise.all(mechanicsPromises);

        const map = {};
        mechanicsSnapshots.forEach((snap) => {
          if (snap.exists()) {
            map[snap.id] = snap.data().name || 'N/A'; // Assuming 'name' field exists
          }
        });
        setMechanicsMap(map);
      } catch (error) {
        console.error('Error fetching mechanics:', error);
        // Optionally, set an error state for mechanics
      }
    };

    if (open && customer.id) {
      fetchJobs();
    }
  }, [customer.id, statusFilter, open]);

  // Fetch vehicles whenever customer.id changes
  useEffect(() => {
    const fetchVehicles = async () => {
      setVehiclesLoading(true);
      setVehiclesError(null);
      try {
        const vehiclesRef = collection(db, 'customers', customer.id, 'vehicles');
        const q = query(
          vehiclesRef,
          orderBy('make'),
          limit(20)
        );
        const querySnapshot = await getDocs(q);
        const vehicleList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setVehicles(vehicleList);
      } catch (error) {
        console.error('Error fetching vehicles:', error);
        setVehiclesError(`Failed to load vehicles: ${error.message}`);
      }
      setVehiclesLoading(false);
    };

    if (open && customer.id) {
      fetchVehicles();
    }
  }, [customer.id, open]);

  // Function to save customer details
  const saveCustomerDetails = async () => {
    if (!hasEditCustomers) {
      showBanner('You do not have permission to edit customer details.', 'error');
      return;
    }

    // Validation: Ensure required fields are not empty
    if (!name.trim() || !email.trim() || !contactNumber.trim()) {
      showBanner('Please fill in all required fields (Name, Email, Contact Number).', 'error');
      return;
    }

    try {
      const customerRef = doc(db, 'customers', customer.id);
      await updateDoc(customerRef, {
        name: name.trim(),
        email: email.trim(),
        contactNumber: contactNumber.trim(),
        address: address.trim(),
        updatedAt: serverTimestamp(),
      });
      showBanner('Customer details updated successfully.', 'success');
      // Optionally, you can choose to not close the modal here
      // onClose();
    } catch (error) {
      console.error('Error updating customer details:', error);
      showBanner('Failed to update customer details. Please try again.', 'error');
    }
  };

  // Function to delete a vehicle
  const deleteVehicle = async (vehicleId) => {
    if (!hasEditCustomers) {
      showBanner('You do not have permission to delete vehicles.', 'error');
      return;
    }

    if (!window.confirm('Are you sure you want to delete this vehicle? This action cannot be undone.')) {
      return;
    }

    try {
      const vehicleRef = doc(db, 'customers', customer.id, 'vehicles', vehicleId);
      await deleteDoc(vehicleRef);
      setVehicles((prevVehicles) => prevVehicles.filter((vehicle) => vehicle.id !== vehicleId));
      showBanner('Vehicle deleted successfully.', 'success');
    } catch (error) {
      console.error('Error deleting vehicle:', error);
      showBanner('Failed to delete vehicle. Please try again.', 'error');
    }
  };

  // Function to add a new vehicle
  const addVehicle = async () => {
    if (!hasEditCustomers) {
      showBanner('You do not have permission to add vehicles.', 'error');
      return;
    }

    // Validation: Ensure all fields are filled
    if (
      !newVehicle.make.trim() ||
      !newVehicle.model.trim() ||
      !newVehicle.year.trim() ||
      !newVehicle.rego.trim()
    ) {
      showBanner('Please fill in all vehicle fields before adding.', 'error');
      return;
    }

    setAddingVehicle(true);
    try {
      const vehiclesRef = collection(db, 'customers', customer.id, 'vehicles');
      const vehicleData = {
        make: newVehicle.make.trim(),
        model: newVehicle.model.trim(),
        year: newVehicle.year.trim(),
        rego: newVehicle.rego.trim(),
      };
      const docRef = await addDoc(vehiclesRef, vehicleData);
      setVehicles((prevVehicles) => [...prevVehicles, { id: docRef.id, ...vehicleData }]);
      setNewVehicle({
        make: '',
        model: '',
        year: '',
        rego: '',
      });
      showBanner('Vehicle added successfully.', 'success');
    } catch (error) {
      console.error('Error adding vehicle:', error);
      showBanner('Failed to add vehicle. Please try again.', 'error');
    }
    setAddingVehicle(false);
  };

  // Function to open JobDetailsModal
  const openJobDetails = (job) => {
    setSelectedJob(job);
  };

  // Function to close JobDetailsModal
  const closeJobDetails = () => {
    setSelectedJob(null);
  };

  // Function to show banner messages
  const showBanner = (message, severity = 'success') => {
    setBannerMessage(message);
    setBannerSeverity(severity);
    setBannerVisible(true);
    setTimeout(() => setBannerVisible(false), 10000); // Hide after 10 seconds
  };

  // Reset form fields when modal is opened or closed
  useEffect(() => {
    if (open) {
      setName(customer.name);
      setEmail(customer.email);
      setContactNumber(customer.contactNumber);
      setAddress(customer.address);
      setBannerMessage('');
      setBannerVisible(false);
    }
  }, [open, customer]);

  // Helper function to format date
  const formatDateTime = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString();
  };

  return (
    <Modal
      open={open} // Use the 'open' prop instead of hardcoding to 'true'
      onClose={onClose}
      aria-labelledby="customer-details-modal"
      aria-describedby="customer-details-form"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          overflowY: 'auto',
          padding: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: { xs: 2, sm: 4 },
            margin: { xs: 1, sm: 2 },
            width: { xs: '95%', sm: '90%', md: '80%' },
            maxHeight: '90vh',
            overflowY: 'auto',
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          {/* Banner Message */}
          {bannerVisible && (
            <Alert
              severity={bannerSeverity}
              onClose={() => setBannerVisible(false)}
              sx={{ marginBottom: 2 }}
            >
              {bannerMessage}
            </Alert>
          )}

          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Customer Details
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          {/* Customer Information Section */}
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Customer Information
          </Typography>
          <Paper elevation={2} sx={{ padding: 2, marginBottom: 3 }}>
            <Grid container spacing={2}>
              {/* Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={!hasEditCustomers}
                />
              </Grid>
              {/* Email */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={!hasEditCustomers}
                />
              </Grid>
              {/* Contact Number */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Contact Number"
                  variant="outlined"
                  fullWidth
                  required
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  disabled={!hasEditCustomers}
                />
              </Grid>
              {/* Address */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  variant="outlined"
                  fullWidth
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  disabled={!hasEditCustomers}
                />
              </Grid>
            </Grid>
          </Paper>

          {/* Vehicles Section */}
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Vehicles
          </Typography>
          <Paper elevation={2} sx={{ padding: 2, marginBottom: 3 }}>
            {/* Vehicles Table */}
            {vehiclesLoading ? (
              <Box sx={{ textAlign: 'center', padding: 2 }}>
                <CircularProgress />
              </Box>
            ) : vehiclesError ? (
              <Typography color="error">{vehiclesError}</Typography>
            ) : vehicles.length > 0 ? (
              <TableContainer>
                <Table aria-label="vehicles table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Make</TableCell>
                      <TableCell>Model</TableCell>
                      <TableCell>Year</TableCell>
                      <TableCell>Rego</TableCell>
                      {hasEditCustomers && <TableCell>Actions</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vehicles.map((vehicle) => (
                      <TableRow key={vehicle.id}>
                        <TableCell>{vehicle.make}</TableCell>
                        <TableCell>{vehicle.model}</TableCell>
                        <TableCell>{vehicle.year}</TableCell>
                        <TableCell>{vehicle.rego}</TableCell>
                        {hasEditCustomers && (
                          <TableCell>
                            <IconButton
                              aria-label="delete"
                              color="error"
                              onClick={() => deleteVehicle(vehicle.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No vehicles found for this customer.</Typography>
            )}

            {/* Add Vehicle Form */}
            {hasEditCustomers && (
              <Box sx={{ marginTop: 4 }}>
                <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                  Add New Vehicle
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  {/* Make */}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Make"
                      variant="outlined"
                      fullWidth
                      required
                      value={newVehicle.make}
                      onChange={(e) => setNewVehicle({ ...newVehicle, make: e.target.value })}
                    />
                  </Grid>
                  {/* Model */}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Model"
                      variant="outlined"
                      fullWidth
                      required
                      value={newVehicle.model}
                      onChange={(e) => setNewVehicle({ ...newVehicle, model: e.target.value })}
                    />
                  </Grid>
                  {/* Year */}
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Year"
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      inputProps={{ min: '1886', max: new Date().getFullYear() + 1 }}
                      value={newVehicle.year}
                      onChange={(e) => setNewVehicle({ ...newVehicle, year: e.target.value })}
                    />
                  </Grid>
                  {/* Rego */}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Rego"
                      variant="outlined"
                      fullWidth
                      required
                      value={newVehicle.rego}
                      onChange={(e) => setNewVehicle({ ...newVehicle, rego: e.target.value })}
                    />
                  </Grid>
                  {/* Add Button */}
                  <Grid item xs={12} sm={1} sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      aria-label="add vehicle"
                      color="primary"
                      onClick={addVehicle}
                      disabled={addingVehicle}
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Paper>

          {/* Jobs Section */}
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Jobs
          </Typography>
          <Paper elevation={2} sx={{ padding: 2, marginBottom: 3 }}>
            {/* Status Filter */}
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="status-filter-label">Filter by Status</InputLabel>
                  <Select
                    labelId="status-filter-label"
                    label="Filter by Status"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    <MenuItem value="">All Statuses</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="In Progress">In Progress</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* Jobs Table */}
            {jobsLoading ? (
              <Box sx={{ textAlign: 'center', padding: 2 }}>
                <CircularProgress />
              </Box>
            ) : jobsError ? (
              <Typography color="error">{jobsError}</Typography>
            ) : jobs.length > 0 ? (
              <TableContainer>
                <Table aria-label="jobs table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Service Required</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Priority</TableCell>
                      <TableCell>Appointment Date</TableCell>
                      <TableCell>Mechanic</TableCell>
                      <TableCell>Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobs.map((job) => (
                      <TableRow
                        key={job.id}
                        hover
                        onClick={() => openJobDetails(job)}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell>
                          {Array.isArray(job.serviceRequired) && job.serviceRequired.length > 0
                            ? job.serviceRequired
                                .map((service) => service.serviceName || 'N/A')
                                .join(', ')
                            : 'N/A'}
                        </TableCell>
                        <TableCell>{job.status}</TableCell>
                        <TableCell>{job.priorityLevel}</TableCell>
                        <TableCell>
                          {job.appointmentDate
                            ? formatDateTime(job.appointmentDate)
                            : 'N/A'}
                        </TableCell>
                        <TableCell>{mechanicsMap[job.mechanicId] || 'N/A'}</TableCell>
                        <TableCell>
                          {job.createdAt
                            ? formatDateTime(job.createdAt)
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No jobs found for this customer.</Typography>
            )}
          </Paper>

          {/* Action Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
            {hasEditCustomers && (
              <Button
                variant="contained"
                color="primary"
                onClick={saveCustomerDetails}
                sx={{ marginRight: 2 }}
              >
                Save
              </Button>
            )}
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>
          </Box>

          {/* Job Details Modal */}
          {selectedJob && (
            <JobDetailsModal job={selectedJob} onClose={closeJobDetails} open={Boolean(selectedJob)} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerDetailsModal;
