// PrivateRoute.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

const PrivateRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    // You can render a loading spinner here
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/auth" />;
};

export default PrivateRoute;