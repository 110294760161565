// StatusUpdater.jsx
import React from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Button, Typography } from '@mui/material';

const StatusUpdater = ({ jobId, currentStatus }) => {
  const statuses = ['Pending', 'In Progress', 'Completed'];

  const nextStatus = () => {
    const currentIndex = statuses.indexOf(currentStatus);
    return statuses[(currentIndex + 1) % statuses.length];
  };

  const updateStatus = async () => {
    const jobRef = doc(db, 'jobs', jobId);
    const newStatus = nextStatus();
    await updateDoc(jobRef, {
      status: newStatus,
    });
  };

  return (
    <div>
      <Typography variant="h6">Current Status: {currentStatus}</Typography>
      <Button variant="contained" onClick={updateStatus}>
        Move to {nextStatus()}
      </Button>
    </div>
  );
};

export default StatusUpdater;
