// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  limit, 
  doc, 
  updateDoc, 
  deleteDoc, 
  addDoc 
} from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDyw1u3GxztT6bHCVs60nQy-PsEYkLDb40",
    authDomain: "mechanic-manager-1a2df.firebaseapp.com",
    databaseURL: "https://mechanic-manager-1a2df-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "mechanic-manager-1a2df",
    storageBucket: "mechanic-manager-1a2df.firebasestorage.app",
    messagingSenderId: "731273125776",
    appId: "1:731273125776:web:b30b232dcf3303db265d15"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const realtimeDb = getDatabase(app);
const storage = getStorage(app);

/**
 * Fetches users from Firestore based on provided filters and sorting.
 * @param {string} shopId - The ID of the shop to filter users by.
 * @param {string} searchTerm - The term to search for within users.
 * @param {string} roleFilter - The role to filter users by.
 * @param {string} statusFilter - The status to filter users by.
 * @param {string} sortField - The field to sort users by.
 * @param {string} sortOrder - The order of sorting ('asc' or 'desc').
 * @returns {Promise<Array>} - A promise that resolves to an array of user objects.
 */
export const getUsersFromFirestore = async (
  shopId,
  searchTerm = '',
  roleFilter = '',
  statusFilter = '',
  sortField = 'createdAt',
  sortOrder = 'asc'
) => {
  try {
    const usersRef = collection(db, 'users');
    let qConstraints = [where('shopId', '==', shopId)];
    
    // Apply Search Filter
    if (searchTerm) {
      // Assuming 'searchField' is a concatenated lowercase string of searchable user attributes
      qConstraints.push(
        where('searchField', '>=', searchTerm.toLowerCase()),
        where('searchField', '<=', searchTerm.toLowerCase() + '\uf8ff')
      );
    }

    // Apply Role Filter
    if (roleFilter) {
      qConstraints.push(where('role', '==', roleFilter));
    }

    // Apply Status Filter
    if (statusFilter) {
      qConstraints.push(where('status', '==', statusFilter));
    }

    // Apply Sorting
    if (sortField) {
      qConstraints.push(orderBy(sortField, sortOrder));
    } else {
      qConstraints.push(orderBy('createdAt', 'desc'));
    }

    // Limit the number of results
    qConstraints.push(limit(50)); // Adjust the limit as needed

    const q = query(usersRef, ...qConstraints);
    const userSnapshot = await getDocs(q);
    const userList = userSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return userList;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

/**
 * Updates a user document in Firestore.
 * @param {string} userId - The ID of the user to update.
 * @param {Object} updatedUserData - The updated user data.
 * @returns {Promise<void>}
 */
export const updateUserInFirestore = async (userId, updatedUserData) => {
  if (!userId) {
    throw new Error('User ID is required to update user.');
  }

  const userDocRef = doc(db, 'users', userId);
  try {
    await updateDoc(userDocRef, updatedUserData);
  } catch (error) {
    console.error(`Error updating user (ID: ${userId}):`, error);
    throw error;
  }
};

/**
 * Deletes a user document from Firestore.
 * @param {string} userId - The ID of the user to delete.
 * @returns {Promise<void>}
 */
export const deleteUserFromFirestore = async (userId) => {
  if (!userId) {
    throw new Error('User ID is required to delete user.');
  }

  const userDocRef = doc(db, 'users', userId);
  try {
    await deleteDoc(userDocRef);
  } catch (error) {
    console.error(`Error deleting user (ID: ${userId}):`, error);
    throw error;
  }
};

/**
 * Adds a new user to Firestore.
 * @param {Object} newUserData - The new user data.
 * @returns {Promise<DocumentReference>} - A promise that resolves to the document reference of the new user.
 */
export const addUserToFirestore = async (newUserData) => {
  try {
    const usersRef = collection(db, 'users');
    const userDocRef = await addDoc(usersRef, newUserData);
    return userDocRef;
  } catch (error) {
    console.error('Error adding user:', error);
    throw error;
  }
};

export { app, auth, db, realtimeDb, storage };