// src/pages/Home.js

import React, { useEffect, useState, useMemo } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { auth, db } from '../firebase'; // Ensure correct import paths
import { collection, doc, getDoc, setDoc, query, where, getDocs } from 'firebase/firestore';
import debounce from 'lodash.debounce';
import { useAuthState } from 'react-firebase-hooks/auth';
import CreateShop from '../components/CreateShop'; // Import the CreateShop component

const Home = () => {
  const [user, loadingUser, errorUser] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [inviteCode, setInviteCode] = useState(''); // Will remove this
  const [actionLoading, setActionLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Debounced functions to prevent rapid state updates
  const debouncedSetInviteCode = useMemo(
    () =>
      debounce((value) => {
        setInviteCode(value);
      }, 300),
    []
  );

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const userRef = doc(db, 'users', user.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUserData(userSnap.data());
          } else {
            // If user document doesn't exist, create one
            await setDoc(doc(db, 'users', user.uid), {
              name: user.displayName || 'Unnamed User',
              email: user.email,
              shopId: '', // Initially no shopId
              searchableField: `${user.displayName || 'Unnamed User'} ${user.email}`.toLowerCase(),
              createdAt: new Date(),
              updatedAt: new Date(),
              privileges: {
                admin: false,
                createNewUsers: false,
                editCustomers: false,
                editJobs: false,
                editUsers: false,
                modifyPlugins: false,
                viewCustomers: false,
                viewJobs: false,
                viewPrivileges: false,
                viewUsers: false,
              },
              role: 'User', // Default role
              status: 'Active', // Default status
            });
            setUserData({
              name: user.displayName || 'Unnamed User',
              email: user.email,
              shopId: '',
              searchableField: `${user.displayName || 'Unnamed User'} ${user.email}`.toLowerCase(),
              createdAt: new Date(),
              updatedAt: new Date(),
              privileges: {
                admin: false,
                createNewUsers: false,
                editCustomers: false,
                editJobs: false,
                editUsers: false,
                modifyPlugins: false,
                viewCustomers: false,
                viewJobs: false,
                viewPrivileges: false,
                viewUsers: false,
              },
              role: 'User',
              status: 'Active',
            });
          }
        } catch (err) {
          console.error('Error fetching user data:', err);
          setError('Failed to retrieve user data.');
        }
        setLoadingData(false);
      };

      fetchUserData();
    } else {
      setLoadingData(false);
    }

    // Cleanup debounced functions on unmount
    return () => {
      debouncedSetInviteCode.cancel();
    };
  }, [user, debouncedSetInviteCode]);

  // Handle Invite Code Submission (Will remove this functionality)
  const handleJoinShop = async (e) => {
    e.preventDefault();
    if (!inviteCode.trim()) {
      setError('Please enter a valid invite code.');
      return;
    }

    setActionLoading(true);
    setError('');
    setSuccess('');

    try {
      // Assuming inviteCode is the shop's document ID or a unique field
      // Modify this logic based on how invite codes are structured in your Firestore
      const shopsRef = collection(db, 'shops');
      const q = query(shopsRef, where('inviteCode', '==', inviteCode.trim()));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError('Invalid invite code. Please check and try again.');
      } else {
        const shopDoc = querySnapshot.docs[0];
        const shopId = shopDoc.id;

        // Update user's shopId
        const userRef = doc(db, 'users', user.uid);
        await setDoc(userRef, { shopId }, { merge: true });

        setUserData((prevData) => ({ ...prevData, shopId }));
        setSuccess('Successfully joined the mechanic shop!');
      }
    } catch (err) {
      console.error('Error joining shop:', err);
      setError('An error occurred while joining the shop. Please try again.');
    }

    setActionLoading(false);
  };

  // Remove the "Join Shop" functionality and only use CreateShop
  // Therefore, we can eliminate the inviteCode state and related handlers

  if (loadingUser || loadingData) {
    return (
      <Box sx={{ padding: '2rem', textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (errorUser) {
    return (
      <Box sx={{ padding: '2rem' }}>
        <Alert severity="error">Error: {errorUser.message}</Alert>
      </Box>
    );
  }

  // If user is not authenticated
  if (!user) {
    return (
      <Box sx={{ padding: '2rem', textAlign: 'center' }}>
        <Typography variant="h6">Please log in to continue.</Typography>
      </Box>
    );
  }

  // If user has a shopId, display the original content
  if (userData && userData.shopId) {
    return (
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card>
              <CardContent>
                <Typography variant="h5">Upcoming Appointments</Typography>
                <Typography>Manage upcoming appointments and notifications.</Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Add more cards or components as needed */}
        </Grid>
      </Box>
    );
  }

  // If user does not have a shopId, display create shop interface
  return (
    <Box
      sx={{
        padding: isMobile ? '1rem' : '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Welcome to the Mechanic Shop Management System
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Please create a new shop to get started.
      </Typography>

      {/* Display Success Message */}
      {success && (
        <Snackbar
          open={Boolean(success)}
          autoHideDuration={6000}
          onClose={() => setSuccess('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
            {success}
          </Alert>
        </Snackbar>
      )}

      {/* Display Error Message */}
      {error && (
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={6000}
          onClose={() => setError('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}

      <Grid container spacing={isMobile ? 2 : 4} sx={{ marginTop: '1rem', maxWidth: 800 }}>
        {/* Remove Join Existing Shop Section */}
        {/* Only display CreateShop component */}
        <Grid item xs={12}>
          <CreateShop /> {/* Integrated CreateShop Component */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
