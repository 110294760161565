import React, { useState } from 'react';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import './Auth.css';
// import so i can reroute after login to home page
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerVisible, setBannerVisible] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isLogin) {
        await signInWithEmailAndPassword(auth, email, password);
        showBanner('Logged in successfully');
        navigate('/');
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
        showBanner('Account created successfully');
      }
    } catch (error) {
      let customErrorMessage = '';
      switch (error.code) {
        case 'auth/invalid-email':
          customErrorMessage = 'Invalid email address. Please enter a valid email.';
          break;
        case 'auth/user-disabled':
          customErrorMessage = 'This user account has been disabled.';
          break;
        case 'auth/user-not-found':
          customErrorMessage = 'No user found with this email. Please register first.';
          break;
        case 'auth/wrong-password':
          customErrorMessage = 'Incorrect password. Please try again.';
          break;
        case 'auth/email-already-in-use':
          customErrorMessage = 'This email is already in use. Please use a different email.';
          break;
        case 'auth/weak-password':
          customErrorMessage = 'Password is too weak. Please use a stronger password.';
          break;
        case 'auth/invalid-credential':
          customErrorMessage = 'Invalid credentials.';
          break;
        case 'auth/too-many-requests':
          customErrorMessage = 'Too many requests. Please try again later.';
          break;
        default:
          customErrorMessage = error.message;
      }
      showBanner(customErrorMessage);
    }
  };

  const showBanner = (message) => {
    setBannerMessage(message);
    setBannerVisible(true);
    setTimeout(() => setBannerVisible(false), 10000); // Hide after 3 seconds
  };

  return (
    <div className="auth-container">
      {bannerVisible && <div className="auth-banner animated-banner">{bannerMessage}</div>}
      <div className="auth-card">
        <div className="auth-header">
          <h2 className="auth-title">{isLogin ? 'Login' : 'Register'}</h2>
          <p className="auth-toggle">
            {isLogin ? "Don't have an account? " : 'Already have an account? '}
            <span onClick={() => setIsLogin(!isLogin)} className="toggle-link">
              {isLogin ? 'Register here' : 'Login here'}
            </span>
          </p>
        </div>
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="input-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="auth-input"
              placeholder="Email"
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="auth-input"
              placeholder="Password"
            />
          </div>
          <button type="submit" className="auth-button primary">
            {isLogin ? 'Login' : 'Register'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Auth;