// src/utils/generateSearchField.js

export const generateSearchField = (job, customer, mechanic, vehicle) => {
    const serviceNames = Array.isArray(job.serviceRequired)
      ? job.serviceRequired.map(service => service.serviceName).join(' ')
      : job.serviceRequired.serviceName || '';
  
    const customerName = customer?.name || '';
    const mechanicName = mechanic?.name || '';
    const vehicleDetails = vehicle
      ? `${vehicle.make} ${vehicle.model} ${vehicle.year} ${vehicle.rego || ''}`
      : '';
  
    // Concatenate all searchable fields into a single string, separated by spaces
    const searchString = `${serviceNames} ${customerName} ${mechanicName} ${vehicleDetails}`.toLowerCase();
  
    return searchString;
  };
  