// src/components/modal/JobDetailsModal.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  CircularProgress,
  Grid,
  Link,
  Alert,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  addDoc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import { auth, db } from '../../firebase';
import TimeTracker from '../TimeTracker';
import DocumentUploader from '../DocumentUploader';
import StatusUpdater from '../StatusUpdater';
import ESignature from '../ESignature';
import WOFPrinter from '../WOFPrinter';
import Autocomplete from '@mui/material/Autocomplete';
import { useAuthState } from 'react-firebase-hooks/auth';
import CustomerDetailsModal from './CustomerDetailsModal';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const JobDetailsModal = ({ job, open, onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [customer, setCustomer] = useState(null);
  const [mechanic, setMechanic] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(true);

  // Editable Fields
  const [serviceRequired, setServiceRequired] = useState(job.serviceRequired || []);
  const [estimatedTime, setEstimatedTime] = useState(job.estimatedTime || 0);
  const [appointmentDate, setAppointmentDate] = useState(
    job.appointmentDate
      ? new Date(job.appointmentDate.seconds * 1000).toISOString().slice(0, 16)
      : ''
  );
  const [priorityLevel, setPriorityLevel] = useState(job.priorityLevel || 'Normal');
  const [customerNotes, setCustomerNotes] = useState(job.customerNotes || '');
  const [updating, setUpdating] = useState(false);

  // Preset Services
  const [presetServices, setPresetServices] = useState([]);
  const [loadingPresetServices, setLoadingPresetServices] = useState(false);

  // Mechanics List
  const [mechanicsOptions, setMechanicsOptions] = useState([]);
  const [loadingMechanics, setLoadingMechanics] = useState(false);

  // State for CustomerDetailsModal
  const [openCustomerModal, setOpenCustomerModal] = useState(false);

  // State for Delete Confirmation Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // State for user privileges
  const [hasViewCustomers, setHasViewCustomers] = useState(false);
  const [privilegesLoading, setPrivilegesLoading] = useState(true);
  const [privilegesError, setPrivilegesError] = useState(null);

  // State for banner messages
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerSeverity, setBannerSeverity] = useState('success'); // 'success' | 'error'
  const [bannerVisible, setBannerVisible] = useState(false);

  // Get current authenticated user
  const [authUser, authLoading, authError] = useAuthState(auth);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchPrivileges = async () => {
      if (!authUser) {
        setHasViewCustomers(false);
        setPrivilegesLoading(false);
        return;
      }

      try {
        const userDocRef = doc(db, 'users', authUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userPrivileges = userData.privileges || {};
          setHasViewCustomers(Boolean(userPrivileges.viewCustomers));
        } else {
          console.warn('User document does not exist.');
          setHasViewCustomers(false);
        }
      } catch (error) {
        console.error('Error fetching user privileges:', error);
        setPrivilegesError('Failed to fetch user privileges.');
      }
      setPrivilegesLoading(false);
    };

    fetchPrivileges();
  }, [authUser]);

  useEffect(() => {
    const fetchDetails = async () => {
      if (privilegesLoading) return; // Wait until privileges are loaded

      setLoading(true);
      try {
        // Fetch customer data
        if (hasViewCustomers && job.customerId) {
          const customerRef = doc(db, 'customers', job.customerId);
          const customerSnap = await getDoc(customerRef);
          setCustomer(customerSnap.exists() ? { id: customerSnap.id, ...customerSnap.data() } : null);
        } else {
          setCustomer(null);
        }

        // Fetch mechanic data
        if (job.mechanicId) {
          const mechanicRef = doc(db, 'users', job.mechanicId);
          const mechanicSnap = await getDoc(mechanicRef);
          setMechanic(mechanicSnap.exists() ? { id: mechanicSnap.id, ...mechanicSnap.data() } : null);
        } else {
          setMechanic(null);
        }

        // Fetch vehicle data
        if (job.vehicleId && job.customerId) {
          const vehicleRef = doc(db, 'customers', job.customerId, 'vehicles', job.vehicleId);
          const vehicleSnap = await getDoc(vehicleRef);
          setVehicle(vehicleSnap.exists() ? { id: vehicleSnap.id, ...vehicleSnap.data() } : null);
        } else {
          setVehicle(null);
        }

        // Fetch available mechanics
        await fetchMechanics();

        // Fetch preset services
        await fetchPresetServices();
      } catch (error) {
        console.error('Error fetching details:', error);
      }
      setLoading(false);
    };

    const fetchMechanics = async () => {
      if (!job.shopId) {
        console.error('shopId is undefined');
        return;
      }
      setLoadingMechanics(true);
      try {
        const usersRef = collection(db, 'users');
        const q = query(
          usersRef,
          where('shopId', '==', job.shopId),
          where('role', '==', 'Mechanic')
        );
        const querySnapshot = await getDocs(q);
        const mechanics = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMechanicsOptions(mechanics);
      } catch (error) {
        console.error('Error fetching mechanics:', error);
      }
      setLoadingMechanics(false);
    };

    const fetchPresetServices = async () => {
      if (!job.shopId) {
        console.error('shopId is undefined');
        return;
      }
      setLoadingPresetServices(true);
      try {
        const servicesRef = collection(db, 'services');
        const q = query(
          servicesRef,
          where('shopId', '==', job.shopId),
          orderBy('name')
        );
        const querySnapshot = await getDocs(q);
        const services = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPresetServices(services);
      } catch (error) {
        console.error('Error fetching preset services:', error);
      }
      setLoadingPresetServices(false);
    };

    fetchDetails();
    // eslint-disable-next-line
  }, [job, hasViewCustomers, privilegesLoading]);

  const handleSave = async () => {
    // Validation
    if (
      serviceRequired.length === 0 ||
      !estimatedTime ||
      isNaN(estimatedTime) ||
      parseFloat(estimatedTime) <= 0
      // Mechanic is optional now
    ) {
      showBanner('Please provide valid service details and estimated time.', 'error');
      return;
    }

    // Additional Validation: Ensure all service names are filled
    for (let i = 0; i < serviceRequired.length; i++) {
      const service = serviceRequired[i];
      if (!service.serviceName || service.serviceName.trim() === '') {
        showBanner(`Service name at row ${i + 1} is required.`, 'error');
        return;
      }
      if (service.customerPrice < 0) {
        showBanner(`Customer price at row ${i + 1} cannot be negative.`, 'error');
        return;
      }
    }

    setUpdating(true);
    try {
      // Since we're not adding custom services to the global 'services' collection,
      // we can directly use the serviceRequired array as is.

      // Prepare services data
      const servicesData = serviceRequired.map((service) => ({
        serviceId: service.serviceId || null, // null for custom services
        serviceName: service.serviceName,
        price : service.price  || 0,
      }));

      // Optional: Handle reassignment in mechanics' assignedJobs
      if (mechanic && mechanic.id !== job.mechanicId) {
        // Remove job from old mechanic's assignedJobs
        if (job.mechanicId) {
          const oldMechanicRef = doc(db, 'users', job.mechanicId);
          await updateDoc(oldMechanicRef, {
            assignedJobs: arrayRemove(job.id),
          });
        }

        // Add job to new mechanic's assignedJobs
        const newMechanicRef = doc(db, 'users', mechanic.id);
        await updateDoc(newMechanicRef, {
          assignedJobs: arrayUnion(job.id),
        });
      }

      // If mechanic is unset, remove job from old mechanic's assignedJobs
      if (!mechanic && job.mechanicId) {
        const oldMechanicRef = doc(db, 'users', job.mechanicId);
        await updateDoc(oldMechanicRef, {
          assignedJobs: arrayRemove(job.id),
        });
      }

      // Update job document
      const jobRef = doc(db, 'jobs', job.id);
      await updateDoc(jobRef, {
        serviceRequired: servicesData,
        estimatedTime: parseFloat(estimatedTime),
        appointmentDate: appointmentDate ? new Date(appointmentDate) : null,
        priorityLevel,
        customerNotes,
        mechanicId: mechanic ? mechanic.id : null, // Update mechanicId or set to null
        updatedAt: serverTimestamp(),
      });

      setUpdating(false);
      showBanner('Job updated successfully!', 'success');
      // Optionally, refetch job details or notify parent component
      onClose();
    } catch (error) {
      console.error('Error updating job:', error);
      setUpdating(false);
      showBanner('Error updating job. Please try again.', 'error');
    }
  };

  // Handlers for CustomerDetailsModal
  const openCustomerModalHandler = () => {
    setOpenCustomerModal(true);
  };

  const closeCustomerModalHandler = () => {
    setOpenCustomerModal(false);
  };

  // Function to show banner messages
  const showBanner = (message, severity = 'success') => {
    setBannerMessage(message);
    setBannerSeverity(severity);
    setBannerVisible(true);
    setTimeout(() => setBannerVisible(false), 10000); // Hide after 10 seconds
  };

  // Handler to add a new service
  const handleAddService = () => {
    setServiceRequired([
      ...serviceRequired,
      {
        serviceId: null, // Custom service
        serviceName: '',
        price : 0,
      },
    ]);
  };

  // Handler to remove a service
  const handleRemoveService = (index) => {
    const updatedServices = [...serviceRequired];
    updatedServices.splice(index, 1);
    setServiceRequired(updatedServices);
  };

  // Handler to update a service's details
  const handleServiceChange = (index, field, value) => {
    const updatedServices = [...serviceRequired];
    updatedServices[index][field] = value;
    setServiceRequired(updatedServices);
  };

  // Handler to open Delete Confirmation Dialog
  const handleDeleteJob = () => {
    setOpenDeleteDialog(true);
  };

  // Handler to confirm deletion
  const confirmDeleteJob = async () => {
    setOpenDeleteDialog(false);
    setUpdating(true);
    try {
      // Delete the job document
      const jobRef = doc(db, 'jobs', job.id);
      await deleteDoc(jobRef);

      // If a mechanic is assigned, remove the job from their assignedJobs
      if (mechanic && mechanic.id) {
        const mechanicRef = doc(db, 'users', mechanic.id);
        await updateDoc(mechanicRef, {
          assignedJobs: arrayRemove(job.id),
        });
      }

      setUpdating(false);
      showBanner('Job deleted successfully!', 'success');
      onClose(); // Close the modal after deletion
    } catch (error) {
      console.error('Error deleting job:', error);
      setUpdating(false);
      showBanner('Error deleting job. Please try again.', 'error');
    }
  };

  // Render Service Required as a table for detailed input
  const renderServicesTable = () => (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table aria-label="services table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '75%' }}>Service Name</TableCell>
            <TableCell sx={{ width: '20%' }}>Price ($)</TableCell>
            <TableCell align="center" sx={{ width: '5%' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceRequired.map((service, index) => (
            <TableRow key={index}>
              <TableCell>
                <Autocomplete
                  options={presetServices}
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || '')}
                  value={service.serviceName || ''}
                  freeSolo
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      // Custom input as service name
                      handleServiceChange(index, 'serviceId', null); // Reset serviceId
                      handleServiceChange(index, 'serviceName', newValue);
                    } else if (newValue && newValue.id) {
                      // Preset service selected
                      handleServiceChange(index, 'serviceId', newValue.id);
                      handleServiceChange(index, 'serviceName', newValue.name);
                      handleServiceChange(index, 'price', newValue.price || 0);
                    } else {
                      // Reset to empty
                      handleServiceChange(index, 'serviceId', null);
                      handleServiceChange(index, 'serviceName', '');
                      handleServiceChange(index, 'price', 0);
                    }
                  }}
                  onInputChange={(event, inputValue) => {
                    // Update the service name when typing in freeSolo mode
                    handleServiceChange(index, 'serviceName', inputValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Service" variant="outlined" required fullWidth />
                  )}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  type="number"
                  value={service.price}
                  onChange={(e) =>
                    handleServiceChange(index, 'price', parseFloat(e.target.value) || 0)
                  }
                  inputProps={{ step: 0.01, min: 0 }}
                  fullWidth
                  size="small"
                />
              </TableCell>
              <TableCell align="center">
                <IconButton aria-label="delete service" color="error" onClick={() => handleRemoveService(index)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {/* Add New Service Row */}
          <TableRow>
            <TableCell colSpan={3} align="center">
              <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddService}>
                Add Service
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
  

  if (loading || privilegesLoading) {
    return (
      <Modal open={open} onClose={onClose} aria-labelledby="job-details-modal" aria-describedby="job-details-form">
        <Box
          sx={{
            backgroundColor: 'white',
            padding: 4,
            margin: 'auto',
            marginTop: '5%',
            width: { xs: '90%', sm: '70%', md: '50%' },
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  if (privilegesError) {
    return (
      <Modal open={open} onClose={onClose} aria-labelledby="job-details-modal" aria-describedby="job-details-form">
        <Box
          sx={{
            backgroundColor: 'white',
            padding: 4,
            margin: 'auto',
            marginTop: '5%',
            width: { xs: '90%', sm: '70%', md: '50%' },
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Alert severity="error">{privilegesError}</Alert>
          <Button variant="contained" color="primary" onClick={onClose} sx={{ marginTop: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    );
  }

  return (
    <>
      <Modal open={open} onClose={onClose} aria-labelledby="job-details-modal" aria-describedby="job-details-form">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            overflowY: 'auto',
            padding: 2,
          }}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              padding: { xs: 2, sm: 4 },
              margin: { xs: 1, sm: 2 },
              width: { xs: '95%', sm: '90%', md: '80%' },
              maxHeight: '90vh',
              overflowY: 'auto',
              borderRadius: 2,
              boxShadow: 24,
            }}
          >
            {/* Banner Message */}
            {bannerVisible && (
              <Alert
                severity={bannerSeverity}
                onClose={() => setBannerVisible(false)}
                sx={{ marginBottom: 2 }}
              >
                {bannerMessage}
              </Alert>
            )}

            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              Job Details - {job.id}
            </Typography>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Overview" />
              <Tab label="Time Tracking" />
              <Tab label="Documents" />
              <Tab label="Status Updates" />
              <Tab label="E-Signature" />
              <Tab label="WOF Sheet" />
            </Tabs>
            {tabIndex === 0 && (
              <Box sx={{ padding: 2 }}>
                {/* Customer Information */}
                {hasViewCustomers && (
                  <>
                    <Typography variant="h6">Customer Information</Typography>
                    <Typography>
                      Name:{' '}
                      {customer ? (
                        <Link
                          component="button"
                          variant="body1"
                          onClick={openCustomerModalHandler}
                          underline="hover"
                        >
                          {customer.name}
                        </Link>
                      ) : (
                        'N/A'
                      )}
                    </Typography>
                    <Typography>
                      Email:{' '}
                      {customer ? (
                        <Link
                          component="button"
                          variant="body1"
                          onClick={openCustomerModalHandler}
                          underline="hover"
                        >
                          {customer.email}
                        </Link>
                      ) : (
                        'N/A'
                      )}
                    </Typography>
                    <Typography>
                      Contact:{' '}
                      {customer ? (
                        <Link
                          component="button"
                          variant="body1"
                          onClick={openCustomerModalHandler}
                          underline="hover"
                        >
                          {customer.contactNumber}
                        </Link>
                      ) : (
                        'N/A'
                      )}
                    </Typography>
                  </>
                )}

                {/* Vehicle Information */}
                <Typography variant="h6" sx={{ marginTop: hasViewCustomers ? 2 : 0 }}>
                  Vehicle Information
                </Typography>
                <Typography>
                  {vehicle
                    ? `${vehicle.make} ${vehicle.model} (${vehicle.year})`
                    : 'N/A'}
                </Typography>
                <Typography>Rego: {vehicle?.rego || 'N/A'}</Typography>

                {/* Mechanic Information */}
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Mechanic Information
                </Typography>
                <Typography>Name: {mechanic?.name || 'N/A'}</Typography>
                <Typography>Email: {mechanic?.email || 'N/A'}</Typography>

                {/* Job Information */}
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Job Information
                </Typography>
                <Typography>Status: {job.status}</Typography>

                {/* Services Required */}
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Services Required
                </Typography>
                {renderServicesTable()}

                {/* Estimated Time */}
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Estimated Time (hrs)
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={estimatedTime}
                  onChange={(e) => setEstimatedTime(e.target.value)}
                  inputProps={{ step: 0.25, min: 0 }} // 0.25 steps for 15-minute increments
                />

                {/* Appointment Date */}
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Appointment Date
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={appointmentDate}
                  onChange={(e) => setAppointmentDate(e.target.value)}
                />

                {/* Priority Level */}
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Priority Level
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  value={priorityLevel}
                  onChange={(e) => setPriorityLevel(e.target.value)}
                >
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Normal">Normal</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                </TextField>

                {/* Assigned Mechanic */}
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Assigned Mechanic
                </Typography>
                <Autocomplete
                  options={mechanicsOptions}
                  getOptionLabel={(option) => option.name || ''}
                  value={mechanic}
                  onChange={(event, newValue) => setMechanic(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Mechanic (Optional)"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingMechanics ? (
                              <CircularProgress size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  clearOnEscape
                />

                {/* Customer Notes */}
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Customer Notes
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={customerNotes}
                  onChange={(e) => setCustomerNotes(e.target.value)}
                />

                {/* Action Buttons */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteJob}
                    disabled={updating}
                  >
                    Delete Job
                  </Button>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      disabled={updating}
                      sx={{ marginRight: 1 }}
                    >
                      {updating ? 'Saving...' : 'Save'}
                    </Button>
                    <Button onClick={onClose}>Close</Button>
                  </Box>
                </Box>
              </Box>
            )}
            {tabIndex === 1 && (
              <Box sx={{ padding: 2 }}>
                <TimeTracker jobId={job.id} estimatedTime={job.estimatedTime} />
              </Box>
            )}
            {tabIndex === 2 && (
              <Box sx={{ padding: 2 }}>
                <DocumentUploader jobId={job.id} />
              </Box>
            )}
            {tabIndex === 3 && (
              <Box sx={{ padding: 2 }}>
                <StatusUpdater jobId={job.id} currentStatus={job.status} />
              </Box>
            )}
            {tabIndex === 4 && (
              <Box sx={{ padding: 2 }}>
                <ESignature jobId={job.id} />
              </Box>
            )}
            {tabIndex === 5 && (
              <Box sx={{ padding: 2 }}>
                <WOFPrinter job={job} />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Dialog for Deletion */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="delete-job-dialog-title"
        aria-describedby="delete-job-dialog-description"
      >
        <DialogTitle id="delete-job-dialog-title">Delete Job</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-job-dialog-description">
            Are you sure you want to delete this job? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDeleteJob} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Customer Details Modal */}
      {openCustomerModal && customer && (
        <CustomerDetailsModal
          customer={customer}
          open={openCustomerModal} // Pass 'open' prop
          onClose={closeCustomerModalHandler} // Pass 'onClose' prop
        />
      )}
    </>

  );
};

JobDetailsModal.propTypes = {
  job: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired, // Ensure 'open' prop is required
  onClose: PropTypes.func.isRequired,
};

export default JobDetailsModal;
