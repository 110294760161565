// src/components/modal/AddUserForm.jsx

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Alert,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { setDoc, doc, serverTimestamp, getDocs, query, where, collection } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import PropTypes from 'prop-types';
import useCurrentUserWithShop from '../../hooks/useCurrentUserWithShop';

const availablePrivileges = {
  customers: ['viewCustomers', 'editCustomers'],
  users: ['viewUsers', 'editUsers'],
  jobs: ['viewJobs', 'editJobs'],
  admin: ['admin', 'viewPrivileges', 'modifyPlugins', 'createNewUsers'],
  // Add more categories and privileges as needed
};

const AddUserForm = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [username, setUsername] = useState(''); // Username field
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(''); // Password field
  const [confirmPassword, setConfirmPassword] = useState(''); // Confirm Password field
  const [role, setRole] = useState('Mechanic');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [privileges, setPrivileges] = useState({
    viewCustomers: false,
    editCustomers: false,
    viewUsers: false,
    editUsers: false,
    viewJobs: false,
    editJobs: false,
    viewPrivileges: false,
    modifyPlugins: false,
    createNewUsers: false,
    admin: false,
  });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { currentUser, loading: userLoading, error: userError } = useCurrentUserWithShop();

  // State for current user's privileges
  const [hasEditUsers, setHasEditUsers] = useState(false);
  const [hasViewPrivileges, setHasViewPrivileges] = useState(false);
  const [privilegesLoading, setPrivilegesLoading] = useState(true);
  const [privilegesError, setPrivilegesError] = useState(null);

  // Fetch current user's privileges
  useEffect(() => {
    const fetchPrivileges = async () => {
      if (!currentUser) {
        setHasEditUsers(false);
        setHasViewPrivileges(false);
        setPrivilegesLoading(false);
        return;
      }

      try {
        const userData = currentUser;
        const userPrivileges = userData.privileges || {};
        setHasEditUsers(Boolean(userPrivileges.editUsers));
        setHasViewPrivileges(Boolean(userPrivileges.viewPrivileges));
      } catch (error) {
        console.error('Error fetching current user privileges:', error);
        setPrivilegesError('Failed to fetch your privileges.');
      }
      setPrivilegesLoading(false);
    };

    fetchPrivileges();
  }, [currentUser]);

  // Reset form when modal closes
  useEffect(() => {
    if (!open) {
      setName('');
      setUsername('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setRole('Mechanic');
      setPhone('');
      setAddress('');
      setPrivileges({
        viewCustomers: false,
        editCustomers: false,
        viewUsers: false,
        editUsers: false,
        viewJobs: false,
        editJobs: false,
        viewPrivileges: false,
        modifyPlugins: false,
        createNewUsers: false,
        admin: false,
      });
      setError(null);
      setSuccessMessage('');
    }
  }, [open]);

  // Handle privilege checkbox changes
  const handlePrivilegeChange = (event) => {
    const { name, checked } = event.target;
    setPrivileges((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Function to show success messages
  const showSuccess = (message) => {
    setSuccessMessage(message);
    setOpenSnackbar(true);
  };

  // Function to show error messages
  const showError = (message) => {
    setError(message);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!name.trim() || !username.trim() || !email.trim() || !password.trim()) {
      showError('Name, Username, Email, and Password are required.');
      return;
    }

    // Password confirmation
    if (password !== confirmPassword) {
      showError('Passwords do not match.');
      return;
    }

    // Simple email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showError('Please enter a valid email address.');
      return;
    }

    // Password strength validation (e.g., minimum 6 characters)
    if (password.length < 6) {
      showError('Password should be at least 6 characters long.');
      return;
    }

    // Optional: Add phone number validation
    const phoneRegex = /^[0-9]{10,15}$/; // Adjust regex as needed
    if (phone && !phoneRegex.test(phone)) {
      showError('Please enter a valid phone number (10-15 digits).');
      return;
    }

    if (privilegesError) {
      showError(privilegesError);
      return;
    }

    if (userError) {
      showError('Failed to retrieve your user data.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const shopId = currentUser.shopId;

      // Check if the current user has a shop
      if (!shopId) {
        throw new Error('You are not associated with any shop.');
      }

      // Check if the email is already used in the same shop
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('email', '==', email.trim()),
        where('shopId', '==', shopId)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        throw new Error('A user with this email already exists in your shop.');
      }

      // Create user with Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email.trim(), password);
      const newUser = userCredential.user;

      // Optional: Send email verification
      await sendEmailVerification(newUser);

      const searchableField = `${username.trim()} ${email.trim()}`.toLowerCase();

      const assignedPrivileges = Object.keys(privileges).reduce((acc, key) => {
        if (privileges[key]) {
          acc[key] = true;
        }
        return acc;
      }, {});

      // Store additional user data in Firestore with uid as document ID
      await setDoc(doc(db, 'users', newUser.uid), {
        uid: newUser.uid, // Link Firestore user with Auth user via UID
        name: username.trim(), // Set username as 'name'
        email: email.trim(),
        role,
        phone: role === 'Mechanic' ? phone.trim() : null, // Store phone only for Mechanics
        address: address.trim() ? address.trim() : null,
        shopId,
        privileges: assignedPrivileges,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        searchableField,
      });

      setLoading(false);
      showSuccess('User added successfully! An email verification link has been sent.');
      onClose();
    } catch (error) {
      console.error('Error adding user:', error);
      // Handle Firebase Auth errors
      if (error.code) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            showError('This email is already in use.');
            break;
          case 'auth/invalid-email':
            showError('The email address is invalid.');
            break;
          case 'auth/weak-password':
            showError('The password is too weak.');
            break;
          default:
            showError(error.message || 'Error adding user. Please try again.');
            break;
        }
      } else {
        showError(error.message || 'Error adding user. Please try again.');
      }
      setLoading(false);
    }
  };

  // Handler to close the Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSuccessMessage('');
  };

  // Display loading indicator if fetching privileges or user data
  if (privilegesLoading || userLoading) {
    return (
      <Modal open={open} onClose={onClose} aria-labelledby="add-user-modal" aria-describedby="add-user-form">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <>
      <Modal open={open} onClose={onClose} aria-labelledby="add-user-modal" aria-describedby="add-user-form">
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.paper',
              padding: { xs: 2, sm: 4 },
              margin: { xs: 1, sm: 2 },
              width: { xs: '95%', sm: '70%', md: '50%' },
              maxHeight: '90vh',
              overflowY: 'auto',
              borderRadius: 2,
              boxShadow: 24,
            }}
          >
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              Add New User
            </Typography>

            {/* Display Error Message */}
            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {error}
              </Alert>
            )}

            <Grid container spacing={2}>
              {/* Name Field */}
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>

              {/* Username Field */}
              <Grid item xs={12}>
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>

              {/* Email Field */}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              {/* Password Field */}
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  required
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  helperText="Password should be at least 6 characters."
                />
              </Grid>

              {/* Confirm Password Field */}
              <Grid item xs={12}>
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  fullWidth
                  required
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  helperText="Re-enter the password to confirm."
                />
              </Grid>

              {/* Role Selection */}
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="role-label">Role</InputLabel>
                  <Select
                    labelId="role-label"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    label="Role"
                  >
                    <MenuItem value="Owner">Owner</MenuItem>
                    <MenuItem value="Mechanic">Mechanic</MenuItem>
                    <MenuItem value="Assistant">Assistant</MenuItem>
                    {/* Add more roles as needed */}
                  </Select>
                </FormControl>
              </Grid>

              {/* Phone Number Field (Visible only for Mechanics) */}
              {role === 'Mechanic' && (
                <Grid item xs={12}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    helperText="Enter 10-15 digit phone number."
                  />
                </Grid>
              )}

              {/* Address Field */}
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>

              {/* Privileges Selection */}
              {hasViewPrivileges && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Assign Privileges
                  </Typography>
                  {privilegesLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <FormControl component="fieldset" variant="standard">
                      <Grid container spacing={2}>
                        {Object.entries(availablePrivileges).map(([category, privs]) => (
                          <Grid item xs={12} sm={6} key={category}>
                            <Typography variant="subtitle2" gutterBottom>
                              {category.charAt(0).toUpperCase() + category.slice(1)}
                            </Typography>
                            <FormGroup>
                              {privs.map((priv) => (
                                <FormControlLabel
                                  key={priv}
                                  control={
                                    <Checkbox
                                      checked={privileges[priv] || false}
                                      onChange={handlePrivilegeChange}
                                      name={priv}
                                      disabled={!hasEditUsers} // Disable if user lacks 'editUsers' privilege
                                    />
                                  }
                                  label={
                                    // Convert camelCase to readable format
                                    priv
                                      .replace(/([A-Z])/g, ' $1')
                                      .replace(/^./, (str) => str.toUpperCase())
                                  }
                                />
                              ))}
                            </FormGroup>
                          </Grid>
                        ))}
                      </Grid>
                    </FormControl>
                  )}
                </Grid>
              )}

              {/* Form Actions */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading || privilegesLoading}
                >
                  {loading ? 'Adding...' : 'Add User'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      {/* Success Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

AddUserForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddUserForm;
