// src/components/CreateShop.jsx

import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
} from '@mui/material';
import { doc, collection, addDoc, getDoc, serverTimestamp, runTransaction, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Ensure correct import paths
import { useAuthState } from 'react-firebase-hooks/auth';

const CreateShop = () => {
  const [shopName, setShopName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState(''); // Although email is from Firebase Auth, allowing to set/display it
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // For user feedback
  const [successMessage, setSuccessMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Get current authenticated user
  const [user, loadingAuth, authError] = useAuthState(auth);

  // Handler for form submission
  const handleCreateShop = async (e) => {
    e.preventDefault();

    // Input Validation
    if (!shopName.trim()) {
      setError('Shop name cannot be empty.');
      return;
    }

    if (!username.trim()) {
      setError('Username cannot be empty.');
      return;
    }

    if (!user) {
      setError('User not authenticated.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Reference to the user's document
      const userRef = doc(db, 'users', user.uid);

      // Check if the user already owns a shop
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        if (userData.shopId) {
          setError('You already own a shop.');
          setLoading(false);
          return;
        }
      }

      // Run a transaction to ensure atomicity
      await runTransaction(db, async (transaction) => {
        // Reference to the shops collection
        const shopsCollectionRef = collection(db, 'shops');

        // Check if shop name already exists
        const shopQuery = query(shopsCollectionRef, where('name', '==', shopName.trim()));
        const shopSnapshot = await getDocs(shopQuery);
        if (!shopSnapshot.empty) {
          throw new Error('A shop with this name already exists. Please choose a different name.');
        }

        // Add a new shop document
        const newShopRef = await addDoc(shopsCollectionRef, {
          name: shopName.trim(),
          ownerUserId: user.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          status: 'Active', // Assuming a default status
        });

        const shopId = newShopRef.id;

        // Update the user's document with the new shopId and other details
        const searchableField = `${username.trim()} ${user.email}`.toLowerCase();
        transaction.update(userRef, {
          shopId,
          name: username.trim(),
          email: user.email, // Ensure email is up-to-date
          updatedAt: serverTimestamp(),
          searchableField,
        });
      });

      setSuccessMessage('Shop created successfully! You have joined your new shop.');
      setOpenSnackbar(true);
      setShopName(''); // Reset the form
      setUsername('');
      // Note: Email is managed by Firebase Auth, typically shouldn't be set here
    } catch (err) {
      console.error('Error creating shop:', err);
      setError(err.message || 'An unexpected error occurred.');
    }

    setLoading(false);
  };

  // Handler to close the Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSuccessMessage('');
  };

  // Render loading state if authentication is loading
  if (loadingAuth) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  // Render error if authentication failed
  if (authError) {
    return (
      <Box sx={{ padding: 2 }}>
        <Alert severity="error">Authentication Error: {authError.message}</Alert>
      </Box>
    );
  }

  // If user is not authenticated
  if (!user) {
    return (
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <Typography variant="h6">Please log in to create a shop.</Typography>
      </Box>
    );
  }

  return (
    <Card sx={{ padding: '1rem' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Create a New Shop
        </Typography>
        <form onSubmit={handleCreateShop}>
          {/* Username Field */}
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ marginBottom: '1rem' }}
          />

          {/* Shop Name Field */}
          <TextField
            label="Shop Name"
            variant="outlined"
            fullWidth
            required
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
            sx={{ marginBottom: '1rem' }}
          />

          {/* Display User Email (Read-Only) */}
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={user.email}
            InputProps={{
              readOnly: true,
            }}
            sx={{ marginBottom: '1rem' }}
          />

          {/* Error Alert */}
          {error && (
            <Alert severity="error" sx={{ marginBottom: '1rem' }}>
              {error}
            </Alert>
          )}

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            fullWidth
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            {loading ? 'Creating Shop...' : 'Create Shop'}
          </Button>
        </form>
      </CardContent>

      {/* Success Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default CreateShop;
