// src/pages/CustomerManagementPage.jsx

import React, { useEffect, useState, useMemo } from 'react';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  getCountFromServer,
} from 'firebase/firestore';
import { db } from '../firebase';
import {
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Select,
  MenuItem,
  Paper,
  Alert,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddCustomerForm from '../components/form/AddCustomerForm';
import CustomerDetailsModal from '../components/modal/CustomerDetailsModal';
import useCurrentUserWithShop from '../hooks/useCurrentUserWithShop';
import debounce from 'lodash.debounce';

const CustomerManagement = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser, loading: userLoading } = useCurrentUserWithShop();

  // Debounce the search term input
  const debouncedSetSearchTerm = useMemo(
    () =>
      debounce((term) => {
        setDebouncedSearchTerm(term);
      }, 500), // 500ms debounce delay
    []
  );

  useEffect(() => {
    debouncedSetSearchTerm(searchTerm);
    // Cleanup function to cancel debounce on unmount
    return () => {
      debouncedSetSearchTerm.cancel();
    };
  }, [searchTerm, debouncedSetSearchTerm]);

  useEffect(() => {
    if (!userLoading && currentUser) {
      fetchCustomers();
    }
    // eslint-disable-next-line
  }, [currentUser, userLoading, debouncedSearchTerm, sortField, sortOrder]);

  const fetchCustomers = async () => {
    setLoading(true);
    setError(null);

    if (!currentUser || !currentUser.shopId) {
      setError('User not logged in or shop information is missing.');
      setLoading(false);
      return;
    }

    try {
      const customersRef = collection(db, 'customers');
      let q = query(
        customersRef,
        where('shopId', '==', currentUser.shopId)
      );

      if (debouncedSearchTerm) {
        // Ensure 'searchField' exists and is indexed
        q = query(
          q,
          where('searchField', '>=', debouncedSearchTerm.toLowerCase()),
          where('searchField', '<=', debouncedSearchTerm.toLowerCase() + '\uf8ff')
        );
      }

      // Sorting
      if (sortField) {
        q = query(q, orderBy(sortField, sortOrder));
      } else {
        q = query(q, orderBy('createdAt', 'desc'));
      }

      q = query(q, limit(20)); // Implement pagination as needed

      const querySnapshot = await getDocs(q);
      const customerList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Now, for each customer, fetch vehicle and job counts
      const customersWithCounts = await Promise.all(
        customerList.map(async (customer) => {
          // Fetch vehicle count
          const vehiclesRef = collection(db, 'customers', customer.id, 'vehicles');
          const vehiclesQuery = query(vehiclesRef);
          const vehiclesSnapshot = await getCountFromServer(vehiclesQuery);
          const numberOfVehicles = vehiclesSnapshot.data().count;

          // Fetch job count
          const jobsRef = collection(db, 'jobs');
          const jobsQuery = query(jobsRef, where('customerId', '==', customer.id));
          const jobsSnapshot = await getCountFromServer(jobsQuery);
          const totalJobs = jobsSnapshot.data().count;

          return {
            ...customer,
            numberOfVehicles,
            totalJobs,
          };
        })
      );

      setCustomers(customersWithCounts);
    } catch (err) {
      console.error('Error fetching customers:', err);
      setError('An error occurred while fetching customers.');
    }
    setLoading(false);
  };

  const openAddCustomerForm = () => {
    setOpenAddCustomer(true);
  };

  const closeAddCustomerForm = () => {
    setOpenAddCustomer(false);
    fetchCustomers();
  };

  const openCustomerDetails = (customer) => {
    setSelectedCustomer(customer);
  };

  const closeCustomerDetails = () => {
    setSelectedCustomer(null);
    fetchCustomers();
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      // Toggle sort order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  if (userLoading) {
    return (
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!currentUser) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">Please log in to view customers.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Customer Management
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Search Customers"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
              style: { height: '56px' },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Select
            value={sortField}
            onChange={(e) => handleSortChange(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ height: '56px' }}
          >
            <MenuItem value="name">Sort by Name</MenuItem>
            <MenuItem value="createdAt">Sort by Date Added</MenuItem>
            <MenuItem value="numberOfVehicles">Sort by Number of Vehicles</MenuItem>
            <MenuItem value="totalJobs">Sort by Total Jobs</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={openAddCustomerForm}
            fullWidth
            sx={{
              height: '56px',
              marginTop: { xs: '8px', sm: 0 },
            }}
          >
            Add New Customer
          </Button>
        </Grid>
      </Grid>

      {/* Error Alert */}
      {error && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ textAlign: 'center', marginTop: 4 }}>
          <CircularProgress />
        </Box>
      ) : customers.length > 0 ? (
        isMobile ? (
          // Mobile View: Detailed List
          <List>
            {customers.map((customer) => (
              <ListItem
                key={customer.id}
                button
                onClick={() => openCustomerDetails(customer)}
                divider
              >
                <ListItemText
                  primary={customer.name}
                  secondary={
                    <>
                      <Typography variant="body2" color="textPrimary">
                        Email: {customer.email || 'N/A'}
                      </Typography>
                      <Typography variant="body2" color="textPrimary">
                        Phone: {customer.contactNumber || 'N/A'}
                      </Typography>
                      <Typography variant="body2" color="textPrimary">
                        Vehicles: {customer.numberOfVehicles}
                      </Typography>
                      <Typography variant="body2" color="textPrimary">
                        Total Jobs: {customer.totalJobs}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          // Desktop View: Table
          <TableContainer component={Paper}>
            <Table aria-label="customers table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('name')}
                      endIcon={
                        sortField === 'name' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Name
                    </Button>
                  </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('numberOfVehicles')}
                      endIcon={
                        sortField === 'numberOfVehicles' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Vehicles
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('totalJobs')}
                      endIcon={
                        sortField === 'totalJobs' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Total Jobs
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSortChange('createdAt')}
                      endIcon={
                        sortField === 'createdAt' ? (
                          sortOrder === 'asc' ? (
                            <Typography>&uarr;</Typography>
                          ) : (
                            <Typography>&darr;</Typography>
                          )
                        ) : null
                      }
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      Date Added
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer) => (
                  <TableRow
                    key={customer.id}
                    hover
                    onClick={() => openCustomerDetails(customer)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>{customer.name}</TableCell>
                    <TableCell>{customer.email || 'N/A'}</TableCell>
                    <TableCell>{customer.contactNumber || 'N/A'}</TableCell>
                    <TableCell>{customer.numberOfVehicles}</TableCell>
                    <TableCell>{customer.totalJobs}</TableCell>
                    <TableCell>
                      {customer.createdAt
                        ? new Date(customer.createdAt.seconds * 1000).toLocaleDateString()
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      ) : (
        <Typography>No customers found.</Typography>
      )}

      {/* Add Customer Form Modal */}
      <AddCustomerForm open={openAddCustomer} onClose={closeAddCustomerForm} />

      {/* Customer Details Modal */}
      {selectedCustomer && (
        <CustomerDetailsModal
          customer={selectedCustomer}
          open={Boolean(selectedCustomer)}
          onClose={closeCustomerDetails}
        />
      )}
    </Box>
  );
};

export default CustomerManagement;
