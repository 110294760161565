// src/hooks/useCurrentUserWithShop.js

import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const useCurrentUserWithShop = () => {
  const [authUser, loadingAuthUser, errorAuthUser] = useAuthState(auth);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (authUser) {
        try {
          const userDocRef = doc(db, 'users', authUser.uid); // Using uid as document ID
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            setCurrentUser({ uid: userDocSnap.id, ...userDocSnap.data() });
          } else {
            console.warn('User document does not exist.');
            setCurrentUser(null);
          }
        } catch (err) {
          console.error('Error fetching user data:', err);
          setError(err);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    };

    fetchCurrentUser();
  }, [authUser]);

  return { currentUser, loading, error };
};

export default useCurrentUserWithShop;
