// src/hooks/useUserPrivileges.js

import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const useUserPrivileges = () => {
  const [user, loading, error] = useAuthState(auth);
  const [privileges, setPrivileges] = useState({});
  const [privilegesLoading, setPrivilegesLoading] = useState(true);
  const [privilegesError, setPrivilegesError] = useState(null);

  useEffect(() => {
    const fetchPrivileges = async () => {
      if (!user) {
        setPrivileges({});
        setPrivilegesLoading(false);
        return;
      }

      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setPrivileges(userData.privileges || {});
        } else {
          console.warn('User document does not exist.');
          setPrivileges({});
        }
      } catch (err) {
        console.error('Error fetching user privileges:', err);
        setPrivilegesError('Failed to fetch user privileges.');
      }
      setPrivilegesLoading(false);
    };

    fetchPrivileges();
  }, [user]);

  return { privileges, privilegesLoading, privilegesError };
};

export default useUserPrivileges;
