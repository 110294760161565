// DocumentUploader.jsx
import React, { useState, useEffect } from 'react';
import { storage } from '../firebase';
import { ref, uploadBytes, listAll, getDownloadURL } from 'firebase/storage';
import { Button, Typography, List, ListItem, Link } from '@mui/material';

const DocumentUploader = ({ jobId }) => {
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadDocument = async () => {
    if (!file) return;
    const storageRef = ref(storage, `jobs/${jobId}/${file.name}`);
    await uploadBytes(storageRef, file);
    fetchDocuments();
  };

  const fetchDocuments = async () => {
    const listRef = ref(storage, `jobs/${jobId}/`);
    const res = await listAll(listRef);
    const urls = await Promise.all(
      res.items.map((itemRef) => getDownloadURL(itemRef))
    );
    setDocuments(urls);
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <div>
      <Typography variant="h6">Documents</Typography>
      <input type="file" onChange={handleFileChange} />
      <Button variant="contained" onClick={uploadDocument}>
        Upload
      </Button>
      <List>
        {documents.map((url, index) => (
          <ListItem key={index}>
            <Link href={url} target="_blank" rel="noopener">
              Document {index + 1}
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default DocumentUploader;
