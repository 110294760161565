// WOFPrinter.jsx
import React from 'react';
import { jsPDF } from 'jspdf';
import { Button } from '@mui/material';

const WOFPrinter = ({ job }) => {
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text('Warrant of Fitness Sheet', 10, 10);
    doc.text(`Job ID: ${job.id}`, 10, 20);
    doc.text(`Vehicle: ${job.vehicleSpecifics.make} ${job.vehicleSpecifics.model}`, 10, 30);
    // Add more details as needed
    doc.save(`WOF_${job.id}.pdf`);
  };

  return (
    <div>
      <Button variant="contained" onClick={generatePDF}>
        Print WOF Sheet
      </Button>
    </div>
  );
};

export default WOFPrinter;
